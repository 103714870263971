import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FieldDetails } from "components";
import { combineArrays } from "utils";
import _ from "lodash";
import { combiningResults } from "./util";
import { Table } from "antd";
import { uid } from "uid";
import {
	FieldsTable,
	Header,
	MainContainer,
	Title,
	StyledButton,
	EmptyField,
	ExportCSV,
	ExcelIcon,
	CSVDownload,
} from "./styles";

export default function NomenclaturesDetails() {
	const { name } = useParams();
	const { nomenclatures } = useSelector((state) => state.nomenclatures);
	const { dictionaries } = useSelector((state) => state.dictionaries);
	const target = nomenclatures.filter((el) => el.name.toLowerCase() === name.replace("-", " ").toLowerCase())[0];
	const transform = target.transform;
	const formulas = target?.formulas?.map((el) => el.details);
	const [finalOutput, setFinalOutput] = useState([]);
	const [finalCSV, setFinalCSV] = useState([]);
	const [finalColumns, setFinalColumns] = useState([]);
	const [finalData, setFinalData] = useState([]);
	const [hasEmpty, setEmpty] = useState(false);
	const [hasData, setHasData] = useState(false);
	const flattenFormulasArray = Array.prototype.concat.apply([], formulas);
	const fieldWithoutDivider = flattenFormulasArray.filter(
		(el) => el.fieldType !== "divider" && el.fieldType !== "uniqueId"
	);
	const uniqueResultField = _.uniqBy(fieldWithoutDivider, "fieldName");
	const formulaNamesArray = target?.formulas?.map((element) => element.name);

	const addItem = (payload, index) => {
		finalOutput.splice(index, 1, payload);
	};

	const shouldExport = () => {
		if (uniqueResultField.length === finalOutput.length) {
			setEmpty(false);
			exportNomenclature();
		} else {
			setEmpty(true);
		}
	};

	const cancelExport = () => {
		setHasData(false);
	};

	const exportNomenclature = async (e) => {
		const combinedNewArrays = [];

		formulas.forEach((formula) => {
			const updatedFormula = formula.map((item) => ({
				...item,
				...finalOutput.find((key) => item.fieldName === key.fieldName),
			}));
			combinedNewArrays.push(updatedFormula);
		});

		const arrayWithUtm = combinedNewArrays.map((array) => {
			const formula = array.map((innerFormula) => {
				if (innerFormula.utmConfig === "url") {
					innerFormula.details = [{ value: innerFormula.details[0].value + "?" }];
					return innerFormula;
				} else if (innerFormula.utmConfig) {
					const newDetails = [];
					innerFormula.details.forEach((item) => {
						const newItem = { value: `${innerFormula.utmConfig}=${item.value}&` };
						newDetails.push(newItem);
					});
					innerFormula.details = newDetails;
					return innerFormula;
				}
				return innerFormula;
			});
			return formula;
		});

		const detailsArray = arrayWithUtm.map((el) => el.map((item) => item.details));

		const finalArray = detailsArray.map((array) => array.map((details) => details.map((value) => value.value)));

		const combinedArray = finalArray.map((array) => combineArrays(array));

		const mapped = combinedArray.filter((array) => array !== undefined);
		const finalResultsArray = combiningResults(mapped, transform);

		const finalArrayOfString = [formulaNamesArray, ...finalResultsArray];
		setFinalCSV(finalArrayOfString);
		setFinalColumns(
			formulaNamesArray.map((element) => {
				const newElement = {
					title: element,
					dataIndex: element.toLowerCase(),
				};
				return newElement;
			})
		);
		setFinalData(
			finalResultsArray.map((output) => {
				let newOutput = {};
				for (let i = 0; i < formulaNamesArray.length; i++) {
					newOutput[`${formulaNamesArray[i].toLowerCase()}`] = output[i];
				}
				newOutput["key"] = uid();
				return newOutput;
			})
		);
		setHasData(true);
	};

	return (
		<MainContainer>
			<Header>
				<Title>{target?.name?.toUpperCase()}</Title>
				<StyledButton onClick={cancelExport}>Cancel</StyledButton>
				<StyledButton type="primary" onClick={shouldExport} style={{ marginLeft: 20 }}>
					Generate
				</StyledButton>
			</Header>
			<FieldsTable>
				{uniqueResultField.map((el, i) => (
					<>
						{el.fieldType !== "divider" && el.fieldType !== "uniqueId" && (
							<FieldDetails
								el={el}
								dict={dictionaries}
								index={i}
								key={i}
								addItem={addItem}
								finalItems={finalOutput}
								transform={transform}
							/>
						)}
					</>
				))}
			</FieldsTable>
			{hasEmpty && <EmptyField>Don't leave empty fields.</EmptyField>}
			{hasData && (
				<EmptyField>
					<ExportCSV type="primary">
						<CSVDownload filename={`${target.name}.csv`} data={finalCSV}>
							<ExcelIcon />
							Export All
						</CSVDownload>
					</ExportCSV>
					<Table
						dataSource={finalData}
						columns={finalColumns}
						scroll={{ x: "max-content", y: 380 }}
						pagination={{ pageSize: 50 }}
						size={"middle"}
						rowSelection={true}
					/>
				</EmptyField>
			)}
		</MainContainer>
	);
}

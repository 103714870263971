import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import dictionaries from "./dictionaries/dictReducer";
import nomenclatures from "./nomenclatures/nomenclatureReducer";
import { teamReducer as team, inviteReducer as inviteUser } from "./team/teamReducer";
import { userActivate, userData, resetPassword, userLogin } from "./auth/authReducer";
import workspaces from "./workspaces/workspacesReducer";

const persistConfig = {
	key: "root",
	storage,
	whitelist: [],
};

const userPersistConfig = {
	key: "userData",
	storage,
	whitelist: ["data"],
};

const workspacePersistConfig = {
	key: "workspaces",
	storage,
	whitelist: ["currentWorkspace"],
};

const rootReducer = combineReducers({
	dictionaries,
	nomenclatures,
	userActivate,
	team,
	resetPassword,
	inviteUser,
	userData: persistReducer(userPersistConfig, userData),
	userLogin,
	workspaces: persistReducer(workspacePersistConfig, workspaces),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

const initialState = {
	workspaces: [],
	userWorkspaces: [],
	currentWorkspace: false,
	isLoading: false,
	hasError: false,
	hasData: false,
};

export default function workspaceReducer(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "GET_TEAM_WORKSPACE_PENDING":
			return {
				...state,
				isLoading: true,
				hasError: false,
				hasData: false,
			};
		case "GET_TEAM_WORKSPACE_SUCCESS":
			return {
				...state,
				isLoading: false,
				hasError: false,
				hasData: true,
				workspaces: action.payload,
			};
		case "GET_TEAM_WORKSPACE_ERROR":
			return {
				...state,
				isLoading: false,
				hasData: false,
				hasError: true,
			};
		case "GET_USER_WORKSPACE_SUCCESS":
			return {
				...state,
				isLoading: false,
				hasError: false,
				hasData: true,
				userWorkspaces: action.payload,
			};
		case "UPDATE_CURRENT_WORKSPACE":
			return {
				...state,
				currentWorkspace: action.payload,
			};
		case "USER_LOGOUT_SUCCESS":
			return {
				...state,
				isLoading: false,
				hasError: false,
				hasData: false,
				currentWorkspace: false,
				userWorkspaces: [],
				workspaces: [],
			};
	}
	return state;
}

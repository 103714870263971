import styled from "styled-components";
import { Button } from "antd";
import { ReactComponent as excel } from "./excel.svg";
import { CSVLink } from "react-csv";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 250px;
	height: 55px;
`;

export const ExportCSV = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	width: 250px;
	height: 55px;
	gap: 20px;
`;

export const ExcelIcon = styled(excel)`
	width: 25px;
`;

export const FieldsTable = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: 20px;
	gap: 20px;
`;

export const FieldDetails = styled.div`
	display: flex;
	flex-flow: column;
`;

export const FieldTitle = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const EmptyField = styled.span`
	display: flex;
	flex-flow: column;
	font-size: 18px;
	color: red;
	margin: 20px;
	gap: 20px;
`;

export const CSVDownload = styled(CSVLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
`;

import styled from "styled-components";
import { Input } from "antd";

export const FieldDetails = styled.div`
	display: flex;
	flex-flow: column;
`;

export const FieldTitle = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const StyledInput = styled(Input)`
	width: ${(props) => (props.type === "url" ? "400px" : "250px")};
`;

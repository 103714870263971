import styled from "styled-components";

export const TableDiv = styled.div`
	display: flex;
	flex-flow: row;
	margin: 0 20px 0 20px;
	align-items: center;
	justify-content: space-between;
	background: #fafafa;
	padding: 16px;
	font-weight: 500;
	font-size: 14px;
	border-bottom: 1px solid #f0f0f0;
	gap: 40px;
	min-width: 1024px;
	overflow: auto;
`;

export const Name = styled.span`
	width: 20%;
	border-right: 1px solid #ebebeb;
`;

export const CreatedBy = styled.span`
	width: 20%;
	border-right: 1px solid #ebebeb;
`;

export const CreatedOn = styled.span`
	width: 20%;
	border-right: 1px solid #ebebeb;
`;

export const LastUpdated = styled.span`
	width: 20%;
	border-right: 1px solid #ebebeb;
`;

export const Actions = styled.span`
	width: 10%;
`;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ModalDelete } from "components";
import ReactModal from "react-modal";
import * as dayjs from "dayjs";
import { largeConfig } from "./utils";
import {
	Actions,
	CreatedBy,
	CreatedOn,
	DeleteSVG,
	EditSVG,
	IconSquare,
	LastUpdated,
	Name,
	TableDiv,
	ModalContainer,
} from "./styles";

export default function NomenclatureItens(props) {
	const { name, email, created, updated, id, role, formulas } = props;
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const handleDelete = () => {
		setDeleteOpen(true);
	};

	const handleCancel = () => {
		setDeleteOpen(false);
	};

	return (
		<>
			<ReactModal ariaHideApp={false} isOpen={isDeleteOpen} style={largeConfig}>
				<ModalContainer>
					<ModalDelete type="nomenclature" id={id} closeModal={handleCancel} name={name} formulas={formulas} />
				</ModalContainer>
			</ReactModal>
			<TableDiv>
				<Name to={`${name.replace(" ", "-").toLowerCase()}`}>{name}</Name>
				<CreatedBy>{email}</CreatedBy>
				<CreatedOn>{dayjs(created).format("DD-MM-YYYY")}</CreatedOn>
				<LastUpdated>{dayjs(updated).format("DD-MM-YYYY")}</LastUpdated>
				{role === "manager" && (
					<Actions>
						<NavLink to={`edit/${name.replace(" ", "-").toLowerCase()}`} state={id}>
							<IconSquare edit>
								<EditSVG />
							</IconSquare>
						</NavLink>
						<IconSquare onClick={handleDelete}>
							<DeleteSVG />
						</IconSquare>
					</Actions>
				)}
			</TableDiv>
		</>
	);
}

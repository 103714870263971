import styled from "styled-components";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as editIcon } from "./edit.svg";
import { NavLink } from "react-router-dom";

export const TableDiv = styled.div`
	display: flex;
	flex-flow: row;
	margin: 0 20px 0 20px;
	font-size: 14px;
	border-bottom: 1px solid #f0f0f0;
	padding: 16px;
	align-items: center;
	justify-content: space-between;
	gap: 40px;
`;

export const Name = styled(NavLink)`
	width: 20%;
`;

export const CreatedBy = styled.span`
	width: 20%;
`;

export const CreatedOn = styled.span`
	width: 20%;
`;

export const LastUpdated = styled.span`
	width: 20%;
`;

export const Actions = styled.div`
	display: flex;
	flex-flow: row;
	width: 10%;
	gap: 10px;
`;

export const IconSquare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	background-color: ${(props) => (props.edit ? "#E7DEFE" : "#FFE1E2")};
	cursor: pointer;
`;

export const DeleteSVG = styled(deleteIcon)`
	margin: 0 0 0 0px;
`;

export const EditSVG = styled(editIcon)`
	margin: 0 0 0 0px;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`;

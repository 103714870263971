import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { Breadcrumbs, NavBar, SideBar } from "components";
import decode from "jwt-decode";
import { userLogout } from "store/auth/authActions";
import { ColumnWrapper, ContentWrapper } from "globalStyle";

export function User() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.userData.data);
	const decodedToken = user ? decode(user.token) : null;
	const expiredToken = decodedToken?.exp * 1000 < new Date().getTime();

	useEffect(() => {
		if (expiredToken) {
			dispatch(userLogout(navigate));
		}
	}, [expiredToken, dispatch, navigate]);

	useEffect(() => {
		if (!user) {
			navigate("/");
		}
	}, [user, dispatch, navigate]);

	return (
		<>
			<SideBar />
			<ColumnWrapper>
				<NavBar />
				<Breadcrumbs />
				<ContentWrapper>
					<Outlet />
				</ContentWrapper>
			</ColumnWrapper>
		</>
	);
}

export function Manager() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.userData.data);
	const userRole = user.data.role;
	const decodedToken = user ? decode(user.token) : null;
	const expiredToken = decodedToken?.exp * 1000 < new Date().getTime();

	useEffect(() => {
		if (expiredToken) {
			dispatch(userLogout(navigate));
		}
	}, [expiredToken, dispatch, navigate]);

	useEffect(() => {
		if (userRole === "user") {
			navigate("/nomenclatures");
		}
	}, [user, userRole, dispatch, navigate]);

	return (
		<>
			<SideBar />
			<ColumnWrapper>
				<NavBar />
				<Breadcrumbs />
				<ContentWrapper>
					<Outlet />
				</ContentWrapper>
			</ColumnWrapper>
		</>
	);
}

export function Guest() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.userData.data);

	useEffect(() => {
		if (user) {
			navigate("/nomenclatures");
		}
	}, [user, navigate]);

	return (
		<>
			<Outlet />
		</>
	);
}

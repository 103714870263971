import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userCreate } from "store/auth/authActions";
import {
	LoginContainer,
	MainContainer,
	StyledInput,
	StyledButton,
	StyledForm,
	StyledButtons,
	MainTitle,
	VersionTitle,
} from "./styles";

export default function UserCreate() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userName, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleName = (e) => {
		setName(e.target.value);
	};

	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleConfirm = (e) => {
		setConfirmPassword(e.target.value);
	};

	const handleSubmit = (e) => {
		if (password === confirmPassword) {
			const payload = {
				email: email,
				name: userName,
				password: password,
				role: "manager",
			};
			dispatch(userCreate(payload, navigate));
		}
	};

	return (
		<MainContainer>
			<LoginContainer>
				<MainTitle>NAMENI</MainTitle>
				<VersionTitle>v 0.1</VersionTitle>
				<StyledForm onSubmit={handleSubmit}>
					<StyledInput type="name" placeholder="name" onChange={handleName} value={userName} />
					<StyledInput type="email" placeholder="email" value={email} onChange={handleEmail} />
					<StyledInput type="password" placeholder="password" onChange={handlePassword} value={password} />
					<StyledInput
						type="password"
						placeholder="confirm password"
						onChange={handleConfirm}
						value={confirmPassword}
					/>
					<StyledButtons>
						<StyledButton type="primary" onClick={handleSubmit}>
							Create User
						</StyledButton>
						<StyledButton onClick={() => navigate(-1)}>Back</StyledButton>
					</StyledButtons>
				</StyledForm>
			</LoginContainer>
		</MainContainer>
	);
}

import React, { useState } from "react";
import { FormulaTitle, FormulaDetailsDiv, IconSquare, EditSVG, DeleteSVG, Actions } from "./styles";

export default function FormulaCollapse(props) {
	const { index, el, collapse, remove } = props;

	return (
		<FormulaDetailsDiv>
			<FormulaTitle>{el.name}</FormulaTitle>
			<Actions>
				<IconSquare edit onClick={(e) => collapse(index)}>
					<EditSVG />
				</IconSquare>
				<IconSquare onClick={(e) => remove(index)}>
					<DeleteSVG />
				</IconSquare>
			</Actions>
		</FormulaDetailsDiv>
	);
}

const initialState = {
	team: [],
	workspaces: [],
	hasError: false,
	isLoading: false,
};

const inviteUserState = {
	inviteError: false,
	inviteLoading: false,
	inviteSent: false,
};

export function teamReducer(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "GET_TEAM_USERS_PENDING":
			return {
				...state,
				hasError: false,
				isLoading: true,
			};
		case "GET_TEAM_USERS_SUCCESS":
			return {
				...state,
				team: action.payload.teamUsers,
				workspaces: action.payload.teamWorkspaces,
				hasError: false,
				isLoading: false,
			};
	}
	return state;
}

export function inviteReducer(state = inviteUserState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "USER_INVITE_PENDING":
			return {
				...state,
				inviteError: false,
				inviteLoading: true,
				inviteSent: false,
			};
		case "USER_INVITE_SUCCESS":
			return {
				...state,
				inviteError: false,
				inviteLoading: false,
				inviteSent: true,
			};
		case "USER_INVITE_ERROR":
			return {
				...state,
				inviteError: true,
				inviteLoading: false,
				inviteSent: false,
			};
		case "USER_INVITE_CLEAR":
			return {
				...state,
				inviteError: false,
				inviteLoading: false,
				inviteSent: false,
			};
	}
	return state;
}

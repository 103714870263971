const initialState = {
	loginLoading: false,
	loginError: false,
	data: undefined,
};

const resetPasswordState = {
	pwError: false,
	pwSent: false,
};

const userSignIn = {
	loginLoading: false,
	loginError: false,
};

export function userActivate(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "GET_USER_SUCCESS":
			return {
				...state,
				userActivate: action.payload,
			};
	}
	return state;
}

export function userLogin(state = userSignIn, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "USER_SIGNIN_PENDING":
			return {
				...state,
				loginLoading: true,
				loginError: false,
			};
		case "USER_SIGNIN_SUCCESS":
			return {
				...state,
				loginLoading: false,
				loginError: false,
			};
		case "USER_SIGNIN_ERROR":
			return {
				...state,
				loginLoading: false,
				loginError: true,
			};
		case "USER_SIGNUP_PENDING":
			return {
				...state,
				loginLoading: true,
				loginError: false,
			};
		case "USER_SIGNUP_SUCCESS":
			return {
				...state,
				loginLoading: false,
				loginError: false,
			};
		case "USER_SIGNUP_ERROR":
			return {
				...state,
				loginLoading: false,
				loginError: true,
			};
	}
	return state;
}

export function userData(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "USER_SIGNIN_PENDING":
			return {
				...state,
				loginLoading: true,
				loginError: false,
			};
		case "USER_SIGNIN_SUCCESS":
			return {
				...state,
				loginLoading: false,
				loginError: false,
				data: action.payload,
			};
		case "USER_SIGNIN_ERROR":
			return {
				...state,
				loginLoading: false,
				loginError: true,
			};
		case "USER_SIGNIN_CLEAR":
			return {
				...state,
				loginLoading: false,
				loginError: false,
			};
		case "USER_SIGNUP_SUCCESS":
			return {
				...state,
				data: action.payload,
			};
		case "USER_LOGOUT_SUCCESS":
			return {
				...state,
				data: action.payload,
			};
	}
	return state;
}

export function resetPassword(state = resetPasswordState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "RESET_PASSWORD_PENDING":
			return {
				...state,
				pwSent: false,
				pwError: false,
			};
		case "RESET_PASSWORD_SUCCESS":
			return {
				...state,
				pwSent: true,
				pwError: false,
			};
		case "RESET_PASSWORD_ERROR":
			return {
				...state,
				pwSent: false,
				pwError: true,
			};
		case "RESET_PASSWORD_CLEAR":
			return {
				...state,
				pwSent: false,
				pwError: false,
			};
	}
	return state;
}

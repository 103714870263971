import React from "react";
import { Modal, Radio, Select } from "antd";
import { InfoDiv, StyledInput, Value, CategoryContainer, StyledRadioButton, StyledSelect } from "./styles";

export default function ModalEditTeam(props) {
	const { Option } = Select;
	const { title, visible, submit, loading, cancel, workspaces, userId, edit } = props;
	const { userEmail, handleUserEmail, handleUserRole, userRole, disabled, handleUserWorkspace } = props;
	const userWorkspaces = workspaces.filter((item) => item.users.includes(userId));

	const preSelectedOptions = userWorkspaces.map((item) => {
		const newObject = {
			children: item.name,
			key: item._id,
			value: item._id,
		};
		return newObject;
	});

	return (
		<Modal
			title={title}
			visible={visible}
			onOk={submit}
			confirmLoading={loading}
			onCancel={cancel}
			maskClosable={false}
			destroyOnClose={true}
		>
			<InfoDiv>
				<Value>User E-Mail:</Value>
				<StyledInput value={userEmail} onChange={handleUserEmail} disabled={disabled} />
				<Value>User Role:</Value>
				<Radio.Group onChange={handleUserRole} value={userRole} buttonStyle="solid" size="large">
					<CategoryContainer>
						<StyledRadioButton value="user">User</StyledRadioButton>
						<StyledRadioButton value="manager">Manager</StyledRadioButton>
					</CategoryContainer>
				</Radio.Group>
				{!edit && (
					<>
						<Value>User Workspaces:</Value>
						<StyledSelect
							mode="tags"
							placeholder="Workspace Members"
							onChange={handleUserWorkspace}
							defaultValue={preSelectedOptions}
						>
							{workspaces.map((member, index) => (
								<Option key={member._id}>{member.name}</Option>
							))}
						</StyledSelect>
					</>
				)}
			</InfoDiv>
		</Modal>
	);
}

import styled from "styled-components";
import { ReactComponent as BreadcrumbsIcon } from "./Breadcrumbs.svg";

export const BreadcrumbsContainer = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 50px;
	min-height: 50px;
	align-items: center;
`;

export const DetailsDiv = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
`;

export const CurrentPath = styled.span`
	display: flex;
	margin: 0 0 0 10px;
`;

export const BreadcrumbsSVG = styled(BreadcrumbsIcon)`
	margin: 0 10px 0 50px;
`;

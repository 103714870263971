import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dictCreate, updateUserDict } from "store/dictionaries/dictActions";
import {
	MainContainer,
	Header,
	Title,
	NameDiv,
	NameInput,
	NameValue,
	MustField,
	ValuesContainer,
	AddContainer,
	AddValues,
	ValueTitle,
	Details,
	NameSpan,
	CodeSpan,
	CodeInput,
	ValueInput,
	StyledButton,
	DeleteSVG,
	IconSquare,
} from "./styles";

export default function AddDictionaries() {
	const routeParams = useParams();
	const { state, pathname } = useLocation();
	const { dictionaries } = useSelector((state) => state.dictionaries);
	const targetedDict = dictionaries.filter(
		(el) => el?.name?.toLowerCase() === routeParams?.name?.replace("-", " ").toLowerCase()
	)[0];
	const [dictionary, setDictionary] = useState(targetedDict ? targetedDict.details : [{ value: "", code: "" }]);
	const { currentWorkspace } = useSelector((state) => state.workspaces);
	const [name, setName] = useState(targetedDict ? targetedDict.name : "");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const pageTitle = pathname.includes("edit") ? "EDIT" : "NEW";
	const userRole = useSelector((state) => state.userData.data.data.role);
	const editable = userRole === "manager" ? false : true;

	useEffect(() => {}, [dictionary]);

	useEffect(() => {
		if (routeParams.name !== undefined) {
			setDictionary(targetedDict?.details);
			setName(targetedDict?.name);
		}
	}, [targetedDict, routeParams.name]);

	const handleAdd = () => {
		setDictionary([...dictionary, { value: "", code: "" }]);
	};

	const handleChange = (i, e) => {
		const newDictValues = [...dictionary];
		newDictValues[i][e.target.name] = e.target.value;
		setDictionary(newDictValues);
	};

	const handleDelete = (i) => {
		const newDictValues = [...dictionary];
		newDictValues.splice(i);
		setDictionary(newDictValues);
	};

	const handleName = (e) => {
		const name = e.target.value;
		setName(name);
	};

	const handleSubmit = (e) => {
		const payload = { name: name, details: dictionary, workspace: currentWorkspace.id };
		if (pathname.includes("edit")) {
			dispatch(updateUserDict(state, payload, currentWorkspace));
			navigate(-1);
		} else {
			dispatch(dictCreate(payload, currentWorkspace));
			navigate(-1);
		}
	};

	const handleCancel = (e) => {
		navigate("/dictionaries");
	};

	return (
		<MainContainer>
			<Header>
				<Title>{pageTitle} DICTIONARY</Title>
				<StyledButton onClick={handleCancel}>Cancel</StyledButton>
				{userRole === "manager" && (
					<StyledButton type="primary" onClick={handleSubmit}>
						Save
					</StyledButton>
				)}
			</Header>
			<NameDiv>
				<NameValue>Name:</NameValue>
				<NameInput onChange={handleName} value={name} />
				<MustField>* Required Field</MustField>
			</NameDiv>
			<ValuesContainer>
				<AddContainer>
					<ValueTitle>VALUES</ValueTitle>
					{userRole === "manager" && <AddValues onClick={handleAdd}>+ Add</AddValues>}
				</AddContainer>
				<Details>
					<NameSpan>Name:</NameSpan>
					<CodeSpan>Code:</CodeSpan>
				</Details>
				{dictionary?.map((el, index) => (
					<Details key={index}>
						<ValueInput
							name="value"
							disabled={editable}
							value={el.value || ""}
							onChange={(e) => handleChange(index, e)}
						/>
						<CodeInput name="code" disabled={editable} value={el.code || ""} onChange={(e) => handleChange(index, e)} />

						{userRole === "manager" && (
							<IconSquare onClick={(e) => handleDelete(index)}>
								<DeleteSVG />
							</IconSquare>
						)}
					</Details>
				))}
			</ValuesContainer>
		</MainContainer>
	);
}

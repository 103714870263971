import React from "react";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import { capitalize } from "lodash";
import { BreadcrumbsContainer, BreadcrumbsSVG, DetailsDiv } from "./styles";

export default function Breadcrumbs() {
	const location = useLocation();
	const currentPath = location.pathname;
	let pathArray = currentPath.split("/");

	return (
		<BreadcrumbsContainer>
			<DetailsDiv>
				<BreadcrumbsSVG />
				<Breadcrumb>
					{pathArray.map((item, index) => {
						if (item === "") {
							return (
								<Breadcrumb.Item key={index}>
									<Link to="/">Home</Link>
								</Breadcrumb.Item>
							);
						} else {
							return <Breadcrumb.Item key={index}>{capitalize(item)}</Breadcrumb.Item>;
						}
					})}
				</Breadcrumb>
			</DetailsDiv>
		</BreadcrumbsContainer>
	);
}

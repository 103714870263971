import { Tag, Space, Tooltip } from "antd";
import { Delete, Edit } from "./styles";

export const columnsFunction = (handleDeleteUser, handleEditUser) => {
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "E-Mail",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "User Role",
			key: "role",
			dataIndex: "role",
			render: (role) => (
				<span>
					{role.map((tag) => {
						const color = tag === "manager" ? "green" : "geekblue";
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</span>
			),
		},
		{
			title: "Workspaces",
			dataIndex: "workspaces",
			key: "workspaces",
			render: (workspaces) => (
				<Tooltip
					placement="topLeft"
					title={workspaces.map((item, index) => (
						<div key={index}>
							<span>{item.name}</span>
							<br />
						</div>
					))}
				>
					<span key={workspaces._id}>{workspaces.length} Workspaces</span>
				</Tooltip>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Space size="middle">
					<Edit onClick={() => handleEditUser(record.key, record.email)}>Edit</Edit>
					<Delete onClick={() => handleDeleteUser(record.key, record.name)}>Delete</Delete>
				</Space>
			),
		},
	];
	return columns;
};

export const inviteErrorNotification = (notification, placement) => {
	notification.info({
		message: `E-Mail already registered.`,
		description: "This e-mail has been already registered. Please assign a different one.",
		placement,
		duration: 3,
	});
};

export const inviteSentNotification = (notification, placement) => {
	notification.info({
		message: `Invite sent.`,
		description: "The user will receive an e-mail with next steps.",
		placement,
		duration: 3,
	});
};

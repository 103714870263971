import styled from "styled-components";
import { Button, Select } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

export const NavBarDiv = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	height: 100px;
	min-height: 100px;
	background: #fff;
	justify-content: space-between;
	align-items: center;
`;

export const DropDownDiv = styled.div`
	display: flex;
	flex-flow: row;
	width: 250px;
	height: 55px;
	margin-right: 40px;
	align-items: center;
	justify-content: center;
	border: 1px solid black;
`;

export const UserButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-right: 40px;
	width: 250px;
	height: 55px;
	border-radius: 7px;
`;

export const Username = styled.span`
	flex-grow: 1;
`;

export const UserSVG = styled(UserOutlined)`
	width: 35px;
	font-size: 18px;
`;

export const ArrowSVG = styled(DownOutlined)``;

export const WorkspaceSelect = styled(Select)`
	width: 274px;
	height: 55px !important;
	margin-left: 40px;

	& .ant-select-selector {
		height: 55px !important;
		text-align: center;
		align-items: center !important;
		border-radius: 7px !important;
	}
`;

import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route } from "react-router-dom";
import { GlobalStyle, Wrapper } from "globalStyle/index";
import {
	Home,
	Links,
	Dictionaries,
	Nomenclatures,
	NomenclatureAdd,
	DictionariesAdd,
	NomenclatureDetails,
	Team,
	UserActivate,
	Account,
	UserCreate,
	Workspaces,
} from "pages";
import "antd/dist/antd.min.css";
import { Guest, User, Manager } from "layout";

export default function App() {
	ReactGA.initialize("G-S3BYVV04Z2");

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: window.location.pathname });
	}, []);

	return (
		<>
			<Wrapper>
				<GlobalStyle />
				<Routes>
					<Route element={<Guest />}>
						<Route path="/create" element={<UserCreate />} />
						<Route path="/activate/:id" element={<UserActivate />} />
						<Route path="/" element={<Home />} />
					</Route>
					<Route element={<User />}>
						<Route path="/nomenclatures" element={<Nomenclatures />} />
						<Route path="/nomenclatures/:name" element={<NomenclatureDetails />} />
						<Route path="/dictionaries" element={<Dictionaries />} />
						<Route path="/dictionaries/edit/:name" element={<DictionariesAdd />} />
						<Route path="/links" element={<Links />} />
						<Route path="/team" element={<Team />} />
						<Route path="/account" element={<Account />} />
						<Route path="/workspaces" element={<Workspaces />} />
					</Route>
					<Route element={<Manager />}>
						<Route path="/nomenclatures/new" element={<NomenclatureAdd />} />
						<Route path="/nomenclatures/edit/:name" element={<NomenclatureAdd />} />
						<Route path="/dictionaries/new" element={<DictionariesAdd />} />
					</Route>
				</Routes>
			</Wrapper>
		</>
	);
}

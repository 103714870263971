import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "store/auth/authActions";
import logo from "./nameni-logo.png";
import {
	AccountSVG,
	DictionariesSVG,
	ItensDiv,
	Logo,
	LogoDiv,
	LogoutDiv,
	LogoutSVG,
	NavContainer,
	NavItem,
	NavLogout,
	NomenclaturesSVG,
	StyledLink,
	TeamSVG,
	WorkSVG,
} from "./styles";

export default function SideBar() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLogout = () => {
		dispatch(userLogout(navigate));
	};

	return (
		<NavContainer>
			<ItensDiv>
				<LogoDiv>
					<Logo src={logo} />
				</LogoDiv>
				<StyledLink className={(navData) => (navData.isActive ? "active" : "")} to="nomenclatures">
					<NomenclaturesSVG />
					<NavItem>Nomenclatures</NavItem>
				</StyledLink>
				<StyledLink className={(navData) => (navData.isActive ? "active" : "")} to="dictionaries">
					<DictionariesSVG />
					<NavItem>Dictionaries</NavItem>
				</StyledLink>
				<StyledLink className={(navData) => (navData.isActive ? "active" : "")} to="team">
					<TeamSVG />
					<NavItem>Team</NavItem>
				</StyledLink>
				<StyledLink className={(navData) => (navData.isActive ? "active" : "")} to="workspaces">
					<WorkSVG />
					<NavItem>Workspaces</NavItem>
				</StyledLink>
				<StyledLink className={(navData) => (navData.isActive ? "active" : "")} to="account">
					<AccountSVG />
					<NavItem>Account</NavItem>
				</StyledLink>
				<LogoutDiv onClick={handleLogout}>
					<LogoutSVG />
					<NavLogout>Logout</NavLogout>
				</LogoutDiv>
			</ItensDiv>
		</NavContainer>
	);
}

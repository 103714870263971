import React, { useState } from "react";
import ReactModal from "react-modal";
import { largeConfig } from "./utils";
import { Input } from "antd";
import {
	AddContainer,
	AddField,
	FieldDetails,
	FieldsTable,
	FieldTitle,
	FormulaDiv,
	FormulaInput,
	FormulaValue,
	ModalContainer,
	StructureTitle,
	StyledButton,
	Data,
	DeleteIcon,
} from "./styles";
import AddFieldURL from "../AddURLField";

export default function URLDetails(props) {
	const { details } = props.formula;
	const { index } = props;
	const [showAddField, setAddField] = useState(false);
	const data = { fieldType: "textfield", fieldName: "Landing Page", utmConfig: "url", index: 0 };
	const [fields, setField] = useState(details ? details : [data]);

	const handleField = (e) => {
		setAddField(true);
	};

	const handleClose = (e) => {
		setAddField(false);
	};

	const pullData = (data) => {
		const arrayLength = fields.length;
		setField([...fields, { ...data, index: arrayLength }]);
	};

	const removeField = (itemIndex) => {
		const newFields = fields.filter((item) => item !== itemIndex);
		setField(newFields);
	};

	const saveFormula = () => {
		const payload = { name: "Landing Page", type: "url", details: fields };
		props.saveFormula(payload, index);
	};

	return (
		<>
			<FormulaDiv>
				<FormulaValue>URL Formulas</FormulaValue>
				<FormulaInput value="Landing Page" />
			</FormulaDiv>
			<AddContainer>
				<StructureTitle>STRUCTURE</StructureTitle>
				<AddField onClick={handleField}>Add Field</AddField>
			</AddContainer>
			<ReactModal ariaHideApp={false} isOpen={showAddField} style={largeConfig}>
				<ModalContainer>
					<AddFieldURL closeModal={handleClose} index={props.index} getField={pullData} />
				</ModalContainer>
			</ReactModal>
			<FieldsTable>
				{fields.map((el, i) => (
					<FieldDetails key={i}>
						{el.fieldName === "Landing Page" && (
							<>
								<FieldTitle>URL</FieldTitle>
								<Input style={{ borderRadius: 7 }} placeholder={el.fieldName} disabled={true} />
							</>
						)}
						{el.fieldName !== "Landing Page" && (
							<>
								<FieldTitle>
									{el.utmConfig} | {el.fieldType}
								</FieldTitle>
								<Data>
									<Input style={{ borderRadius: 7 }} placeholder={el.fieldName} disabled={true} />
									<DeleteIcon onClick={() => removeField(el)} />
								</Data>
							</>
						)}
					</FieldDetails>
				))}
			</FieldsTable>
			<StyledButton onClick={saveFormula}>Save Formula</StyledButton>
		</>
	);
}

import api from "../../api";

export const dictCreate = (payload, currentWorkspace) => async (dispatch) => {
	try {
		dispatch({
			type: "CREATE_DICTIONARY_PENDING",
		});
		const { data } = await api.createDictionary(payload);
		dispatch(getUserDict(currentWorkspace.id));
		dispatch({
			type: "CREATE_DICTIONARY_SUCCESS",
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: "CREATE_DICTIONARY_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const getUserDict = (workspaceId) => async (dispatch) => {
	try {
		dispatch({
			type: "GET_DICTIONARY_PENDING",
		});
		const { data } = await api.getUserDictionaries(workspaceId);
		const dictionaries = data.data;
		dispatch({
			type: "GET_DICTIONARY_SUCCESS",
			payload: dictionaries,
		});
	} catch (error) {
		dispatch({
			type: "GET_DICTIONARY_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const deleteUserDict = (id, currentWorkspace) => async (dispatch) => {
	try {
		dispatch({
			type: "DELETE_DICTIONARY_PENDING",
		});
		const { data } = await api.deleteUserDictionary(id, currentWorkspace);
		dispatch(getUserDict(currentWorkspace.id));
		dispatch({
			type: "DELETE_DICTIONARY_SUCCESS",
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: "DELETE_DICTIONARY_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const updateUserDict = (id, updatedDict, currentWorkspace) => async (dispatch) => {
	try {
		dispatch({
			type: "UPDATE_DICTIONARY_PENDING",
		});
		const updated = await api.updateUserDictionary(id, updatedDict);
		dispatch({
			type: "UPDATE_DICTIONARY_SUCCESS",
			payload: updated,
		});
		dispatch(getUserDict(currentWorkspace.id));
	} catch (error) {
		dispatch({
			type: "UPDATE_DICTIONARY_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

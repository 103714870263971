import styled from "styled-components";
import { Button, Input } from "antd";
import { ReactComponent as deleteIcon } from "./delete.svg";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 250px;
	height: 55px;
	margin-left: 20px;
	font-size: 16px;
`;

export const NameDiv = styled.div`
	display: flex;
	flex-flow: column;
	margin: 0 20px 0 20px;
	border-bottom: 1px solid #c2cfe0;
`;

export const NameValue = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const NameInput = styled(Input)`
	width: 400px;
	height: 55px;
	border-radius: 7px;
`;

export const MustField = styled.span`
	font-size: 10px;
	color: red;
	margin: 10px 0 10px 0;
`;

export const ValuesContainer = styled.div`
	display: flex;
	flex-flow: column;
	margin: 20px;
	gap: 10px;
`;

export const AddContainer = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin-bottom: 20px;
`;

export const ValueTitle = styled.span`
	font-size: 24px;
	font-weight: 500;
`;

export const AddValues = styled(Button)`
	border-radius: 28px;
	width: 175px;
	height: 55px;
	margin-left: 50px;
`;

export const Details = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	width: 800px;
	gap: 30px;
`;

export const NameSpan = styled.span`
	width: 300px;
	font-size: 14px;
`;

export const CodeSpan = styled.span`
	width: 300px;
	font-size: 14px;
`;

export const ValueInput = styled(Input)`
	width: 300px;
	height: 55px;
	border-radius: 7px;
`;

export const CodeInput = styled(Input)`
	width: 300px;
	height: 55px;
	border-radius: 7px;
`;

export const IconSquare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-color: ${(props) => (props.edit ? "#E7DEFE" : "#FFE1E2")};
`;

export const DeleteSVG = styled(deleteIcon)`
	margin: 0 0 0 0px;
`;

import { getTeamUsers } from "store/team/teamActions";
import api from "../../api";

export const userLogin = (formData, navigate) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_SIGNIN_PENDING",
		});
		const { data } = await api.loginUser(formData);
		dispatch({
			type: "USER_SIGNIN_SUCCESS",
			payload: data,
		});
		navigate(`/nomenclatures`);
	} catch (error) {
		console.log(error.message, error.response, error.request);
		dispatch({
			type: "USER_SIGNIN_ERROR",
			payload: error.response.status,
		});
		dispatch({
			type: "USER_SIGNIN_CLEAR",
		});
	}
};

export const userCreate = (formData, navigate) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_SIGNUP_PENDING",
		});
		const { data } = await api.createUser(formData);
		dispatch({
			type: "USER_SIGNUP_SUCCESS",
			payload: data,
		});
		navigate(`/nomenclatures`);
	} catch (error) {
		console.log(error.message, error.response, error.request);
		dispatch({
			type: "USER_SIGNUP_FAILURE",
			payload: error.response.status,
		});
	}
};

export const getOneUser = (id) => async (dispatch) => {
	try {
		dispatch({
			type: "GET_USER_PENDING",
		});
		const { data } = await api.getOneUser(id);
		dispatch({
			type: "GET_USER_SUCCESS",
			payload: data,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: "GET_USER_FAILURE",
			payload: error,
		});
	}
};

export const userLogout = (navigate) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_LOGOUT_PENDING",
		});
		navigate(`/`);
		dispatch({
			type: "USER_LOGOUT_SUCCESS",
			payload: "",
		});
	} catch (error) {
		dispatch({
			type: "USER_LOGOUT_FAILURE",
			payload: error,
		});
	}
};

export const userUpdate = (navigate, payload, id) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_UPDATE_PENDING",
		});
		const { data } = await api.updateUser(id, payload);
		navigate(`/nomenclatures`);
		dispatch({
			type: "USER_UPDATE_SUCCESS",
			payload: data,
		});
	} catch (error) {
		console.log(error.message, error.response, error.request);
		dispatch({
			type: "USER_SIGNUP_FAILURE",
			payload: error.response.status,
		});
	}
};

export const deleteUser = (id, teamId) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_DELETE_PENDING",
		});
		const { data } = await api.deleteUser(id);
		dispatch({
			type: "USER_DELETE_SUCCESS",
			payload: data,
		});
		dispatch(getTeamUsers(teamId));
	} catch (error) {
		console.log(error.message, error.response, error.request);
	}
};

export const resetPassword = (email) => async (dispatch) => {
	try {
		dispatch({
			type: "RESET_PASSWORD_PENDING",
		});
		const { data } = await api.resetPasswordEmail(email);
		dispatch({
			type: "RESET_PASSWORD_SUCCESS",
			payload: data,
		});
		dispatch({
			type: "RESET_PASSWORD_CLEAR",
		});
	} catch (error) {
		console.log(error.message, error.response, error.request);
		dispatch({
			type: "RESET_PASSWORD_ERROR",
		});
		dispatch({
			type: "RESET_PASSWORD_CLEAR",
		});
	}
};

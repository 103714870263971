import { React, useState } from "react";
import { useSelector } from "react-redux";
import { Radio, Select } from "antd";
import {
	Buttons,
	SelectCategory,
	StyledButton,
	SelectContainer,
	SelectTitle,
	StyledSelect,
	StyledInput,
	CategoryContainer,
	StyledRadioButton,
} from "./styles";

export default function AddFieldURL(props) {
	const { Option } = Select;

	const [checked, setChecked] = useState("dictionary");
	const [inputValue, setInputValue] = useState("");
	const [dictLabel, setDictLabel] = useState("");
	const [utmValue, setUtm] = useState("");
	const { dictionaries } = useSelector((state) => state.dictionaries);

	const RadioSelect = (e) => {
		setChecked(e.target.value);
	};

	const DictDropdown = (e) => {
		setDictLabel(e);
	};

	const utmDropdown = (e) => {
		setUtm(e);
	};

	const handleInputValue = (e) => {
		setInputValue(e.target.value);
	};

	const handleSave = (e) => {
		sendField();
		props.closeModal();
	};

	const sendField = async () => {
		if (checked === "dictionary") {
			const data = { fieldType: "dictionary", fieldName: dictLabel, utmConfig: utmValue };
			props.getField(data);
		} else if (checked === "textfield") {
			const data = { fieldType: "textfield", fieldName: inputValue, utmConfig: utmValue };
			props.getField(data);
		} else if (checked === "uniqueId") {
			const data = {
				fieldType: "uniqueId",
				fieldName: "uniqueId",
				utmConfig: utmValue,
				details: [{ value: "uniqueId" }],
			};
			props.getField(data);
		}
	};

	const utms = ["utm_source", "utm_content", "utm_medium", "utm_campaign", "utm_term"];

	return (
		<SelectContainer>
			<SelectTitle>Add UTMs</SelectTitle>
			<SelectCategory>UTM Type</SelectCategory>
			<StyledSelect onChange={utmDropdown} defaultValue="">
				{utms.map((el, index) => (
					<Option value={el} key={index} id={index}>
						{el}
					</Option>
				))}
			</StyledSelect>
			<SelectCategory>Category</SelectCategory>
			<Radio.Group onChange={RadioSelect} value={checked} buttonStyle="solid" size="large">
				<CategoryContainer>
					<StyledRadioButton value="dictionary">Dictionary</StyledRadioButton>
					<StyledRadioButton value="textfield">Text Field</StyledRadioButton>
					<StyledRadioButton value="uniqueId">Unique ID</StyledRadioButton>
				</CategoryContainer>
			</Radio.Group>
			{checked === "dictionary" && (
				<>
					<SelectCategory>Select Dictionary</SelectCategory>
					<StyledSelect onChange={DictDropdown} defaultValue="">
						{dictionaries.map((el, index) => (
							<Option value={el.name} key={el._id} id={el._id}>
								{el.name}
							</Option>
						))}
					</StyledSelect>
				</>
			)}
			{checked === "textfield" && (
				<>
					<SelectCategory>Name your field:</SelectCategory>
					<StyledInput placeholder="Name" onChange={handleInputValue} value={inputValue} />
				</>
			)}
			<Buttons>
				<StyledButton onClick={props.closeModal}>Cancel</StyledButton>
				<StyledButton onClick={handleSave} type="primary">
					Save
				</StyledButton>
			</Buttons>
		</SelectContainer>
	);
}

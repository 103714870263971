import React from "react";
import { TableDiv, Name, CreatedBy, CreatedOn, LastUpdated, Actions } from "./styles";

export default function NomenclatureTitle(props) {
	return (
		<TableDiv>
			<Name>Name</Name>
			<CreatedBy>Created by</CreatedBy>
			<CreatedOn>Created on</CreatedOn>
			<LastUpdated>Last Updated</LastUpdated>
			{props.role === "manager" && <Actions>Actions</Actions>}
		</TableDiv>
	);
}

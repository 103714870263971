import styled from "styled-components";
import { Radio, Select, Input, Button } from "antd";

const { TextArea } = Input;

export const SelectContainer = styled.div`
	display: flex;
	flex-flow: column;
	border-radius: 12px;
	width: 600px;
	height: 500px;
	background-color: #fff;
	padding: 20px;
`;

export const StyledRadioButton = styled(Radio.Button)`
	width: 100%;
	text-align: center;
	height: 40px !important;
`;

export const SelectTitle = styled.span`
	font-weight: 500;
	font-size: 20px;
	margin: 15px 0 15px 0;
`;

export const CategoryContainer = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	gap: 20px;
`;

export const StyledSelect = styled(Select)`
	&& .ant-select-selector {
		border-radius: 7px !important;
		height: 40px !important;
		align-items: center;
	}
`;

export const StyledInput = styled(Input)`
	border-radius: 7px;
	height: 40px !important;
	align-items: center;
`;

export const SelectCategory = styled.span`
	font-weight: 300;
	font-size: 14px;
	margin: 15px 0 10px 0;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 116px;
	height: 40px;
`;

export const Buttons = styled.div`
	display: flex;
	flex-flow: row;
	gap: 20px;
	margin: 50px 0 0 0;
	justify-content: center;
`;

export const NoteWrapper = styled.div`
	display: flex;
	margin: 15px 0 10px 0;
	align-items: center;
	gap: 5px;
`;

export const NoteCategory = styled.span`
	font-weight: 300;
	font-size: 14px;
`;

export const Text = styled(TextArea)`
	border-radius: 7px;
`;

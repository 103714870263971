export const largeConfig = {
	// eslint-disable-next-line{
	overlay: {
		backgroundColor: "rgba(25, 27, 31, 0.5)",
	},
	content: {
		WebkitOverflowScrolling: "touch",
		position: "absolute",
		top: "80px",
		left: "80px",
		right: "80px",
		bottom: "80px",
		backgroundColor: "transparent",
		border: "1px solid transparent",
	},
};

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DictTableTitle, DictTableItens } from "components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Header, MainContainer, Title, DictTable, StyledButton, EmptyDict } from "./styles";
import { getUserDict } from "store/dictionaries/dictActions";

export default function Dictionaries() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { dictionaries, isLoading, hasData } = useSelector((state) => state.dictionaries);
	const dictLength = dictionaries.length;
	const loadingIndicator = <LoadingOutlined style={{ fontSize: 96 }} spin />;
	const userRole = useSelector((state) => state.userData.data.data.role);
	const { currentWorkspace } = useSelector((state) => state.workspaces);

	useEffect(() => {
		if (currentWorkspace?.name !== undefined) {
			dispatch(getUserDict(currentWorkspace.id));
		}
	}, [dispatch, currentWorkspace]);

	return (
		<MainContainer>
			<Header>
				<Title>DICTIONARIES</Title>
				{userRole === "manager" && (
					<StyledButton type="primary" onClick={() => navigate("new")}>
						+Add Dictionary
					</StyledButton>
				)}
			</Header>
			<DictTable>
				<Spin indicator={loadingIndicator} spinning={isLoading} style={{ fontSize: 96 }} delay={500} />
				{dictLength < 1 && hasData && (
					<EmptyDict>
						Dictionaries allow to seamless organize your taxonomies. Start by creating a dictionary.
					</EmptyDict>
				)}
				{dictLength > 0 && hasData && (
					<>
						<DictTableTitle role={userRole} />
						{dictionaries.map((el) => (
							<DictTableItens
								name={el?.name}
								email={el?.user?.email || "Deleted User"}
								createdBy={el?.user?.name}
								created={el.createdAt}
								updated={el.updatedAt}
								key={el._id}
								dictId={el._id}
								role={userRole}
							/>
						))}
					</>
				)}
			</DictTable>
		</MainContainer>
	);
}

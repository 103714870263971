import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        font-size: 16px;
        margin: 0;
        font-family: 'Inter', sans-serif;
    }

`;

export const Wrapper = styled.div`
	display: flex;
	flex-flow: row;
	align-content: flex-start;
	width: 100;
	height: 100vh;
	min-width: 1024px;
`;

export const ColumnWrapper = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	background: #f5f6fa;
	align-items: center;
	min-width: 1024px;
`;

export const ContentWrapper = styled.div`
	display: flex;
	width: 95%;
	height: 82%;
	background: #fff;
	min-width: 1024px;
	border-radius: 10px;
`;

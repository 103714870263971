import styled from "styled-components";
import { Button, Input } from "antd";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	height: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const DetailsDiv = styled.div`
	display: flex;
	flex-flow: column;
	margin: 0 20px 20px 20px;
	padding-bottom: 20px;
`;

export const InfoDiv = styled.div`
	display: flex;
	flex-flow: column;
`;

export const Value = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const StyledInput = styled(Input)`
	width: 400px;
	height: 55px;
	border-radius: 7px;
	margin: 0 0 20px 0;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 250px;
	height: 55px;
	margin-left: 20px;
`;

import _ from "lodash";
import { uid } from "uid";

export const combiningResults = (combinedArray) => {
	const finalArray = [];
	const finalArrayDuplicates = [];
	const finalArrayMulti = [];

	if (combinedArray.length === 4) {
		combinedArray[0].forEach((first) =>
			combinedArray[1].forEach((second) =>
				combinedArray[2].forEach((third) =>
					combinedArray[3].forEach((fourth) => {
						if (fourth.includes("utm")) {
							const newString = fourth.replace("utm_campaign=", "");
							const newString2 = newString.replace("utm_term=", "");
							const newString3 = newString2.replace("utm_content=", "");
							const newString4 = newString3.replace("utm_source=", "");
							const newString5 = newString4.replace("utm_medium=", "");
							const newArray = newString5.split("?");
							const newArray2 = newArray.slice(1);
							const newArray3 = newArray2[0].split("&");
							const newFourth = fourth.slice(0, -1);
							// const finalArray2 = newArray3.splice(-1);
							newArray3.splice(-1);
							const mappedArray = newArray3.map((element) => {
								if (first.includes(element) && fourth.includes(element)) {
									return [first, second, third, newFourth];
								} else if (second.includes(element) && fourth.includes(element)) {
									return [first, second, third, newFourth];
								} else if (third.includes(element) && fourth.includes(element)) {
									return [first, second, third, newFourth];
								} else if (first.includes(element)) {
									return [first, second, third, newFourth];
								} else if (second.includes(element)) {
									return [first, second, third, newFourth];
								} else if (third.includes(element)) {
									return [first, second, third, newFourth];
								} else if (fourth.includes(element)) {
									return "duplicate";
								}
								return false;
							});

							// 	if (first.includes(element) && fourth.includes(element)) {
							// 		return [first, second, third, fourth];
							// 	} else if (second.includes(element)) {
							// 		return [first, second, third, fourth];
							// 	} else if (third.includes(element)) {
							// 		return [first, second, third, fourth];
							// 	} else if (fourth.includes(element)) {
							// 		return "duplicate";
							// 	}
							// 	return false;
							// });

							const newtest = _.uniqWith(mappedArray, _.isEqual);

							const mappedArrayId = mappedArray.map((array) => {
								const uniqueId = uid(6);
								if (Array.isArray(array)) {
									const newArray = array.map((item) => {
										if (item.includes("uniqueId")) {
											const newitem = item.replace("uniqueId", uniqueId);
											return newitem;
										} else {
											return item;
										}
									});
									return newArray;
								} else {
									return array;
								}
							});

							const applyingId = newtest.map((array) => {
								const uniqueId = uid(6);
								if (Array.isArray(array)) {
									const newArray = array.map((item) => {
										if (item.includes("uniqueId")) {
											const newitem = item.replace("uniqueId", uniqueId);
											return newitem;
										} else {
											return item;
										}
									});
									return newArray;
								} else {
									return array;
								}
							});

							const countDuplicates = mappedArray.filter((element) => element === "duplicate").length;

							if (countDuplicates === 1) {
								finalArrayMulti.push(mappedArrayId[0]);
							} else if (!newtest.includes("duplicate")) {
								return finalArray.push(applyingId[0]);
							} else {
								return finalArrayDuplicates.push(applyingId[0]);
							}
						} else {
							console.log("dead end");
						}
					})
				)
			)
		);
	} else if (combinedArray.length === 3) {
		combinedArray[0].forEach((first) =>
			combinedArray[1].forEach((second) =>
				combinedArray[2].forEach((third) => {
					finalArray.push([first, second, third]);
				})
			)
		);
	} else if (combinedArray.length === 2) {
		combinedArray[0].forEach((first) => combinedArray[1].forEach((second) => finalArray.push([first, second])));
	} else if (combinedArray.length === 1) {
		combinedArray[0].forEach((first) => finalArray.push([first]));
	}

	if (finalArray.length === 0) {
		return finalArrayMulti;
	} else {
		return finalArray;
	}
};

import styled from "styled-components";
import { Button, Input } from "antd";
import { ReactComponent as deleteIcon } from "./deleteField.svg";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 250px;
	height: 55px;
	margin: 0 20px 20px 20px;
`;

export const NameDiv = styled.div`
	display: flex;
	flex-flow: column;
	margin: 0 20px 20px 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #c2cfe0;
`;

export const NameValue = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const NameInput = styled(Input)`
	width: 400px;
	height: 55px;
	border-radius: 7px;
`;

export const FormulaDiv = styled.div`
	display: flex;
	flex-flow: column;
	margin: 0 20px 20px 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #c2cfe0;
`;

export const FormulaValue = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const FormulaInput = styled(Input)`
	width: 400px;
	height: 55px;
	border-radius: 7px;
`;

export const AddContainer = styled.div`
	display: flex;
	flex-flow: row;
	margin: 0 20px 20px 20px;
	align-items: center;
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
`;

export const StructureTitle = styled.span`
	font-size: 24px;
	font-weight: 700;
`;

export const AddField = styled(Button)`
	border-radius: 28px;
	width: 175px;
	height: 55px;
	margin-left: 50px;
`;

export const FieldsTable = styled.div`
	display: flex;
	flex-flow: row wrap;
	margin: 0 20px 20px 20px;
	gap: 20px;
`;

export const FieldDetails = styled.div`
	display: flex;
	flex-flow: column;
	width: 200px;
`;

export const FieldTitle = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const Data = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	gap: 5px;
`;

export const DeleteIcon = styled(deleteIcon)`
	width: 15px;
	fill: red;
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserDict } from "store/dictionaries/dictActions";
import { deleteUserNome } from "store/nomenclatures/nomenclatureActions";
import { Buttons, DeleteContainer, DeleteText, StyledButton } from "./styles";

export default function ModalDelete(props) {
	const dispatch = useDispatch();
	const { name, closeModal, type, id, formulas } = props;
	const { currentWorkspace } = useSelector((state) => state.workspaces);

	const handleConfirm = () => {
		if (type === "dictionary") {
			dispatch(deleteUserDict(id, currentWorkspace));
			closeModal();
		} else if (type === "nomenclature") {
			dispatch(deleteUserNome(id, currentWorkspace, formulas));
			closeModal();
		}
	};

	return (
		<DeleteContainer>
			<DeleteText>Are you sure you wanna delete the {props.type}:</DeleteText>
			<DeleteText style={{ fontStyle: "oblique", textDecoration: "underline" }}>{name}</DeleteText>
			<Buttons>
				<StyledButton onClick={closeModal}>Cancel</StyledButton>
				<StyledButton onClick={handleConfirm} type="primary">
					Delete
				</StyledButton>
			</Buttons>
		</DeleteContainer>
	);
}

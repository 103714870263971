import styled from "styled-components";
import { Button } from "antd";

export const DeleteContainer = styled.div`
	display: flex;
	flex-flow: column;
	border-radius: 12px;
	width: 600px;
	height: 400px;
	background-color: #fff;
	padding: 20px;
	align-items: center;
	justify-content: center;
`;

export const DeleteText = styled.span`
	font-size: 16px;
`;

export const Buttons = styled.div`
	display: flex;
	flex-flow: row;
	gap: 20px;
	margin: 50px 0 0 0;
	justify-content: center;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 116px;
	height: 40px;
`;

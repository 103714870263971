import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainContainer, Header, Title, StyledButton, DetailsDiv, Value, InfoDiv, DeleteModal } from "./styles";
import { Table, notification } from "antd";
import { columnsFunction, emptyFields, errorAPI } from "./utils";
import {
	createWorkspace,
	deleteWorkspace,
	getTeamWorkspaces,
	updateWorkspace,
} from "store/workspaces/workspaceActions";
import { getTeamUsers } from "store/team/teamActions";
import ModalWorkspace from "components/ModalWorkspace";

export default function Workspaces() {
	const dispatch = useDispatch();
	const { team } = useSelector((state) => state.team) || {};
	const [addWorkspace, setAddWorkspace] = useState(false);
	const [editWorkspace, setEditWorkspace] = useState(false);
	const [workspaceId, setWorkspaceId] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const { workspaces, isLoading, hasError } = useSelector((state) => state.workspaces);
	const [workspaceName, setWorkspaceName] = useState("");
	const [workspaceMembers, setMembers] = useState([]);
	const user = useSelector((state) => state.userData.data);
	const teamId = user?.data?.team[0];

	useEffect(() => {
		dispatch(getTeamWorkspaces());
		dispatch(getTeamUsers(teamId));
	}, [dispatch, teamId]);

	useEffect(() => {
		if (hasError) {
			errorAPI(notification, "topRight");
		}
	}, [dispatch, hasError]);

	const handleWorkspaceName = (e) => {
		setWorkspaceName(e.target.value);
	};

	const handleWorkspaceChange = (value, name) => {
		setMembers(value);
	};

	const showModal = () => {
		setAddWorkspace(true);
	};

	const handleCancel = () => {
		setAddWorkspace(false);
		setDeleteModal(false);
		setEditWorkspace(false);
		setWorkspaceName("");
		setMembers([]);
		setWorkspaceId("");
	};

	const handleSubmit = () => {
		if (workspaceName === "" || workspaceMembers.length === 0) {
			emptyFields(notification, "topRight");
		} else {
			const payload = { name: workspaceName, users: workspaceMembers };
			dispatch(createWorkspace(payload));
			setAddWorkspace(false);
			setWorkspaceName("");
			setMembers([]);
		}
	};

	const handleEditSubmit = () => {
		if (workspaceName === "" || workspaceMembers.length === 0) {
			emptyFields(notification, "topRight");
		} else {
			const payload = { name: workspaceName, users: workspaceMembers };
			dispatch(updateWorkspace(workspaceId, payload));
			setEditWorkspace(false);
			setWorkspaceName("");
			setWorkspaceId("");
			setMembers([]);
		}
	};

	const handleEditWorkspace = (name, users, id) => {
		setEditWorkspace(true);
		setWorkspaceId(id);
		setWorkspaceName(name);
		setMembers(users);
	};

	const confirmDeleteWorkspace = (id) => {
		setDeleteModal(true);
		setWorkspaceId(id);
	};

	const handleDelete = () => {
		dispatch(deleteWorkspace(workspaceId));
		setDeleteModal(false);
	};

	const workspaceData = workspaces.map((element, index) => {
		const object = {
			key: element._id,
			name: element.name,
			createdBy: element.createdBy.name,
			users: element.users,
		};
		return object;
	});

	return (
		<>
			<ModalWorkspace
				title="Add Workspace"
				visible={addWorkspace}
				submit={handleSubmit}
				cancel={handleCancel}
				members={team}
				workspaceName={workspaceName}
				handleWorkspaceName={handleWorkspaceName}
				handleWorkspaceChange={handleWorkspaceChange}
			/>

			<ModalWorkspace
				title="Edit Workspace"
				visible={editWorkspace}
				submit={handleEditSubmit}
				cancel={handleCancel}
				members={team}
				workspaceName={workspaceName}
				edit
				handleWorkspaceName={handleWorkspaceName}
				handleWorkspaceChange={handleWorkspaceChange}
				workspaces={workspaces}
				workspaceId={workspaceId}
			/>
			<DeleteModal title="Delete Workspace" visible={deleteModal} onOk={handleDelete} onCancel={handleCancel}>
				<InfoDiv>Are you sure you wanna delete this user?</InfoDiv>
			</DeleteModal>
			<MainContainer>
				<Header>
					<Title>WORKSPACES</Title>
					<StyledButton type="primary" onClick={showModal}>
						+Add Workspace
					</StyledButton>
				</Header>
				<DetailsDiv>
					<Value>Manage your workspaces and users on this page.</Value>
					<Table
						loading={isLoading}
						columns={columnsFunction(confirmDeleteWorkspace, handleEditWorkspace)}
						dataSource={workspaceData}
					/>
				</DetailsDiv>
			</MainContainer>
		</>
	);
}

const initialState = {
	dictionaries: [],
	isLoading: false,
	hasError: false,
	hasData: false,
};

export default function dictionariesReducer(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "GET_DICTIONARY_PENDING":
			return {
				...state,
				isLoading: true,
				hasError: false,
				hasData: false,
			};
		case "GET_DICTIONARY_SUCCESS":
			return {
				...state,
				dictionaries: action.payload,
				isLoading: false,
				hasError: false,
				hasData: true,
			};
		case "GET_DICTIONARY_ERROR":
			return {
				...state,
				isLoading: false,
				hasError: true,
				hasData: false,
			};
	}
	return state;
}

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { notification, Modal } from "antd";
import { DetailsDiv, Header, StyledButton, InfoDiv, MainContainer, StyledInput, Title, Value } from "./styles";
import { userUpdate } from "store/auth/authActions";

export default function Account() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { name, email, _id } = useSelector((state) => state.userData.data.data);
	const [userName, setName] = useState(name);
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [visible, setVisible] = useState(false);

	const handleName = (e) => {
		setName(e.target.value);
	};

	const handleCurrentPassword = (e) => {
		setCurrentPassword(e.target.value);
	};

	const handleNewPassword = (e) => {
		setNewPassword(e.target.value);
	};

	const showModal = () => {
		const formData = [userName, currentPassword, newPassword];
		if (formData.includes("")) {
			openNotification("topRight");
		} else {
			setVisible(!visible);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setVisible(false);
		const payload = {
			email: email,
			name: userName,
			currentPassword: currentPassword,
			password: newPassword,
			type: "existing",
		};
		dispatch(userUpdate(navigate, payload, _id));
	};

	const openNotification = (placement) => {
		notification.info({
			message: `Missing Data`,
			description: "You have to fill all fields before proceeding.",
			placement,
			duration: 3,
		});
	};

	return (
		<MainContainer>
			<Modal title="Update User Information" visible={visible} onOk={handleSubmit} onCancel={showModal}>
				Are you sure you wanna update your user data?
			</Modal>
			<Header>
				<Title>YOUR ACCOUNT</Title>
				<StyledButton onClick={() => navigate("/nomenclatures")}>Cancel</StyledButton>
				<StyledButton type="primary" onClick={showModal}>
					Save
				</StyledButton>
			</Header>
			<DetailsDiv>
				<form onSubmit={handleSubmit}>
					<InfoDiv>
						<Value>Name:</Value>
						<StyledInput value={userName} onChange={handleName} />
						<Value>E-Mail:</Value>
						<StyledInput value={email} disabled />
						<Value>Current Password:</Value>
						<StyledInput type="password" value={currentPassword} onChange={handleCurrentPassword} autoComplete="off" />
						<Value>New Password:</Value>
						<StyledInput type="password" value={newPassword} onChange={handleNewPassword} autoComplete="off" />
					</InfoDiv>
				</form>
			</DetailsDiv>
		</MainContainer>
	);
}

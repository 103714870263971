import api from "../../api";

export const getTeamUsers = (id) => async (dispatch) => {
	try {
		dispatch({
			type: "GET_TEAM_USERS_PENDING",
		});
		const { data } = await api.getTeamUsers(id);
		const teamUsers = data.data[0].users;
		const teamWorkspaces = data.data[0].workspaces;
		dispatch({
			type: "GET_TEAM_USERS_SUCCESS",
			payload: { teamUsers, teamWorkspaces },
		});
	} catch (error) {
		dispatch({
			type: "GET_TEAM_USERS_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const userInvite = (payload, id) => async (dispatch) => {
	try {
		dispatch({
			type: "USER_INVITE_PENDING",
		});
		const { data } = await api.inviteUser(payload);
		dispatch(getTeamUsers(id));
		dispatch({
			type: "USER_INVITE_SUCCESS",
			payload: data,
		});
		dispatch({
			type: "USER_INVITE_CLEAR",
		});
	} catch (error) {
		console.log(error.message, error.response, error.request);
		dispatch({
			type: "USER_INVITE_ERROR",
			payload: error,
		});
		dispatch({
			type: "USER_INVITE_CLEAR",
		});
	}
};

export const updateTeamUser = (id, payload) => async (dispatch) => {
	try {
		dispatch({
			type: "UPDATE_TEAM_USER_PENDING",
		});
		const { data } = await api.updateTeamUser(id, payload);
		dispatch({
			type: "UPDATE_TEAM_USER_SUCCESS",
			payload: data,
		});
		dispatch(getTeamUsers());
	} catch (error) {
		dispatch({
			type: "UPDATE_TEAM_USER_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

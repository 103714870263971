import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NomenclatureTitle, NomenclatureItens } from "components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { StyledButton, Header, MainContainer, NomenClatureTable, Title, EmptyNome } from "./styles";
import { getUserNome } from "store/nomenclatures/nomenclatureActions";

export default function Nomenclatures() {
	const dispatch = useDispatch();
	const { nomenclatures, isLoading, hasData } = useSelector((state) => state?.nomenclatures);
	const { currentWorkspace } = useSelector((state) => state.workspaces);
	const nomeLength = nomenclatures.length;
	const loadingIndicator = <LoadingOutlined style={{ fontSize: 96 }} spin />;
	const userRole = useSelector((state) => state?.userData?.data?.data?.role);

	useEffect(() => {
		if (currentWorkspace?.name !== undefined) {
			dispatch(getUserNome(currentWorkspace.id));
		}
	}, [dispatch, currentWorkspace]);

	return (
		<MainContainer>
			<Header>
				<Title>NOMENCLATURES</Title>
				{userRole === "manager" && (
					<NavLink to={`new`}>
						<StyledButton type="primary">+Add Nomenclatures</StyledButton>
					</NavLink>
				)}
			</Header>
			<NomenClatureTable>
				<Spin indicator={loadingIndicator} spinning={isLoading} style={{ fontSize: 96 }} delay={500} />
				{nomeLength < 1 && hasData && (
					<EmptyNome>Here's where the magic happens. Start adding Dictionaries to create your nomenclature.</EmptyNome>
				)}
				{nomeLength > 0 && hasData && (
					<>
						<NomenclatureTitle role={userRole} />
						{nomenclatures.map((el) => (
							<NomenclatureItens
								name={el?.name}
								email={el?.user?.email}
								createdBy={el?.user?.name}
								created={el?.createdAt}
								updated={el?.updatedAt}
								id={el?._id}
								key={el?.createdAt}
								role={userRole}
								formulas={el?.formulas}
							/>
						))}
					</>
				)}
			</NomenClatureTable>
		</MainContainer>
	);
}

import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover, notification, Radio, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
	ButtonsDiv,
	DetailsDiv,
	Header,
	MainContainer,
	NameDiv,
	NameInput,
	NameValue,
	StyledButton,
	Title,
	CollapseDiv,
	TransformValue,
	NoteWrapper,
} from "./styles";
import { nomeCreate, updateUserNome } from "store/nomenclatures/nomenclatureActions";
import { FormulaCollapse, FormulaDetails, URLDetails } from "components";
import { Collapse } from "react-collapse";
import { noteDetails } from "./utils";

export default function NomenclatureAdd() {
	const routeParams = useParams();
	const { state, pathname } = useLocation();
	const { nomenclatures } = useSelector((state) => state.nomenclatures);
	const targetedNome = nomenclatures.filter(
		(el) => el?.name?.toLowerCase() === routeParams?.name?.replace("-", " ").toLowerCase()
	)[0];
	const { currentWorkspace } = useSelector((state) => state.workspaces);
	const [nomeName, setNomeName] = useState(targetedNome ? targetedNome.name : "");
	const [formulas, setFormulas] = useState(targetedNome ? targetedNome.formulas : []);
	const [isOpened, setIsOpened] = useState([false, false, false, false]);
	const pageTitle = pathname.includes("edit") ? "EDIT" : "NEW";
	const [transform, setTransform] = useState(targetedNome ? targetedNome.transform : "none");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (routeParams.name !== undefined) {
			setNomeName(targetedNome?.name);
			setFormulas(targetedNome?.formulas);
		}
	}, [targetedNome, routeParams.name]);

	const handleName = (e) => {
		setNomeName(e.target.value);
	};

	const handleTransform = (e) => {
		setTransform(e.target.value);
	};

	const saveNomenclature = (e) => {
		if (pageTitle === "NEW") {
			const updatedPayload = {
				nomenclatureName: nomeName,
				formulas: formulas,
				workspace: currentWorkspace,
				transform,
			};
			dispatch(nomeCreate(updatedPayload, currentWorkspace));
			navigate(-1);
		}
		if (pageTitle === "EDIT") {
			const updatedPayload = {
				nomenclatureName: nomeName,
				formulas: formulas,
				transform,
			};
			dispatch(updateUserNome(state, updatedPayload, currentWorkspace));
			navigate(-1);
		}
	};

	const addFormula = (e) => {
		if (isOpened.includes(true)) {
			openNotification("topRight");
		} else {
			if (e.target.name === "formula") {
			}
			const newFormulas = [...formulas, { name: "New Formula", type: "formula" }];
			const formulasLength = newFormulas.length - 1;
			const openedArray = isOpened.map((el, i) => (i === formulasLength ? !el : el));
			setIsOpened(openedArray);
			setFormulas(newFormulas);
		}
	};

	const addUrl = () => {
		if (isOpened.includes(true)) {
			openNotification("topRight");
		} else {
			const newFormulas = [...formulas, { name: "New URL", type: "url" }];
			const formulasLength = newFormulas.length - 1;
			const openedArray = isOpened.map((el, i) => (i === formulasLength ? !el : el));
			setIsOpened(openedArray);
			setFormulas(newFormulas);
		}
	};

	const handleFormulas = (payload, formulaIndex) => {
		const newFormulas = formulas.map((el, index) => {
			if (index === formulaIndex) {
				const updatedFormula = {
					...el,
					name: payload.name,
					type: payload.type,
					details: payload.details,
				};
				return updatedFormula;
			} else {
				return el;
			}
		});
		setFormulas(newFormulas);
		setIsOpened([false, false, false, false]);
	};

	const trackedURL = (
		<div>
			<p>Tracked URLs require a minimum of 3 formulas.</p>
			<p>For example: Campaign, Ad Group and Ad.</p>
		</div>
	);

	const handleCollapseClick = (index) => {
		if (isOpened.includes(true)) {
			openNotification("topRight");
		} else {
			const openedArray = isOpened.map((el, i) => (i === index ? !el : el));
			setIsOpened(openedArray);
		}
	};

	const handleDelete = (i) => {
		const updatedFormulas = formulas.filter((el, index) => index !== i);
		setFormulas(updatedFormulas);
	};

	const openNotification = (placement) => {
		notification.info({
			message: `Save your formula`,
			description: "Save your formula before proceding with new formulas.",
			placement,
			duration: 3,
		});
	};

	return (
		<MainContainer>
			<Header>
				<Title>{pageTitle} NOMENCLATURE</Title>
				<NavLink to={`/nomenclatures`}>
					<StyledButton>Cancel</StyledButton>
				</NavLink>
				<StyledButton type="primary" style={{ marginLeft: 20 }} onClick={saveNomenclature}>
					Save
				</StyledButton>
			</Header>
			<NameDiv>
				<DetailsDiv>
					<NameValue>Name:</NameValue>
					<NameInput onChange={handleName} value={nomeName} />
					<NoteWrapper>
						<TransformValue>Transform:</TransformValue>
						<Tooltip placement="top" title={noteDetails}>
							<InfoCircleOutlined />
						</Tooltip>
					</NoteWrapper>
					<Radio.Group value={transform} onChange={handleTransform} style={{ marginLeft: "10px" }}>
						<Radio value="none">None</Radio>
						<Radio value="upper">Uppercase</Radio>
						<Radio value="lower">Lowercase</Radio>
					</Radio.Group>
				</DetailsDiv>
				<ButtonsDiv>
					{formulas?.length < 3 && <StyledButton onClick={addFormula}>Add Formula</StyledButton>}
					{formulas?.length >= 3 && formulas.length < 4 && (
						<StyledButton onClick={addUrl} type="primary" style={{ marginLeft: 20 }}>
							Add Tracked URL
						</StyledButton>
					)}
					{formulas?.length < 3 && (
						<Popover content={trackedURL} title="Tracked URL">
							<StyledButton onClick={addUrl} disabled style={{ marginLeft: 20 }}>
								Add Tracked URL
							</StyledButton>
						</Popover>
					)}
				</ButtonsDiv>
			</NameDiv>
			{formulas?.map((el, index) => (
				<CollapseDiv>
					<FormulaCollapse index={index} el={el} collapse={handleCollapseClick} remove={handleDelete} />
					<Collapse isOpened={isOpened[index]}>
						{el.type === "formula" && <FormulaDetails formula={el} saveFormula={handleFormulas} index={index} />}
						{el.type === "url" && <URLDetails formula={el} saveFormula={handleFormulas} index={index} />}
					</Collapse>
				</CollapseDiv>
			))}
		</MainContainer>
	);
}

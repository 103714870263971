export const missingEmail = (notification, placement) => {
	notification.info({
		message: `Missing E-Mail`,
		description: "Please insert your e-mail to reset your password.",
		placement,
		duration: 3,
	});
};

export const wrongEmail = (notification, placement) => {
	notification.info({
		message: `E-Mail doesn't exist`,
		description: "This e-mail doesn't exist.",
		placement,
		duration: 3,
	});
};

export const sendEmail = (notification, placement) => {
	notification.info({
		message: `E-Mail Sent`,
		description: "Check your inbox to reset your password.",
		placement,
		duration: 3,
	});
};

export const loginErrorMsg = (notification, placement) => {
	notification.info({
		message: `Wrong Credentials`,
		description: "Check your e-mail and password.",
		placement,
		duration: 3,
	});
};

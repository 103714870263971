import React, { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { FieldDetails, FieldTitle, StyledInput } from "./styles";
import { transformF } from "./utils";

export default function FieldDetailsComp(props) {
	const { el, dict, index, addItem, transform } = props;
	const targetDict = dict.filter((dict) => dict.name === el.fieldName)[0];
	const { Option } = Select;
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {}, []);

	const handleChange = (e, name) => {
		const elementName = el?.fieldName;
		if (e?.target?.name === elementName) {
			const fieldValue = e.target.value.replace(" ", "-");
			const payload = {
				fieldName: elementName,
				details: [{ value: transformF(transform, fieldValue, e.target.type) }],
			};
			addItem(payload, index);
			setInputValue(fieldValue);
		} else {
			const transformValues = name.map((item) => {
				const newItem = {
					...item,
					value: transformF(transform, item.value, "none"),
				};
				return newItem;
			});
			const payload = { fieldName: elementName, details: transformValues };
			addItem(payload, index);
			setInputValue(name);
		}
	};

	return (
		<FieldDetails>
			<FieldTitle>{el.fieldName}</FieldTitle>
			{el.fieldType === "textfield" && (
				<Tooltip placement="topLeft" title={el.note}>
					<StyledInput name={el.fieldName} type={el.utmConfig} onChange={handleChange} value={inputValue} />
				</Tooltip>
			)}
			{el.fieldType === "dictionary" && (
				<Select style={{ width: 250 }} mode="multiple" maxTagCount="responsive" onChange={handleChange}>
					{targetDict?.details?.map((value, i) => (
						<Option id={el.fieldName} key={i} value={value.code}>
							{value.value}
						</Option>
					))}
				</Select>
			)}
		</FieldDetails>
	);
}

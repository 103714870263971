import React, { useState } from "react";
import { AddFieldComp } from "components";
import ReactModal from "react-modal";
import { Input } from "antd";
import { largeConfig, capitalize } from "./utils";
import {
	AddContainer,
	AddField,
	Data,
	DeleteIcon,
	FieldDetails,
	FieldsTable,
	FieldTitle,
	FormulaDiv,
	FormulaInput,
	FormulaValue,
	ModalContainer,
	StructureTitle,
	StyledButton,
} from "./styles";

export default function FormulaDetails(props) {
	const { name, details } = props.formula;
	const { index } = props;
	const [showAddField, setAddField] = useState(false);
	const [fields, setField] = useState(details ? details : []);
	const [formulaName, setFormulaName] = useState(name ? `${name}` : "");

	const handleFormula = (e) => {
		setFormulaName(e.target.value);
	};

	const handleField = (e) => {
		setAddField(true);
	};

	const handleClose = (e) => {
		setAddField(false);
	};

	const pullData = (data) => {
		const arrayLength = fields.length;
		setField([...fields, { ...data, index: arrayLength }]);
	};

	const removeField = (itemIndex) => {
		const newFields = fields.filter((item) => item !== itemIndex);
		setField(newFields);
	};

	const saveFormula = () => {
		const payload = { name: formulaName, type: "formula", details: fields };
		props.saveFormula(payload, index);
	};

	return (
		<>
			<FormulaDiv>
				<FormulaValue>Formula Name:</FormulaValue>
				<FormulaInput onChange={handleFormula} value={formulaName} />
			</FormulaDiv>
			<AddContainer>
				<StructureTitle>STRUCTURE</StructureTitle>
				<AddField onClick={handleField}>Add Field</AddField>
			</AddContainer>
			<ReactModal ariaHideApp={false} isOpen={showAddField} style={largeConfig}>
				<ModalContainer>
					<AddFieldComp closeModal={handleClose} index={props.index} getField={pullData} />
				</ModalContainer>
			</ReactModal>
			<FieldsTable>
				{fields.map((el, i) => (
					<FieldDetails key={i}>
						<FieldTitle>{capitalize(el.fieldType)}</FieldTitle>
						<Data>
							<Input style={{ borderRadius: 7 }} placeholder={el.fieldName} disabled={true} />
							<DeleteIcon onClick={() => removeField(el)} />
						</Data>
					</FieldDetails>
				))}
			</FieldsTable>
			<StyledButton onClick={saveFormula}>Save Formula</StyledButton>
		</>
	);
}

import styled from "styled-components";
import { Button, Input } from "antd";
import { Collapse } from "antd";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as editIcon } from "./edit.svg";

export const FormulaDetailsDiv = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	height: 50px;
`;

export const FormulaTitle = styled.span`
	display: flex;
	align-items: center;
	height: 50px;
	font-weight: 500;
	margin-left: 20px;
	flex-grow: 1;
`;

export const IconSquare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	background-color: ${(props) => (props.edit ? "#E7DEFE" : "#FFE1E2")};
	cursor: pointer;
`;

export const Actions = styled.div`
	display: flex;
	flex-flow: row;
	gap: 10px;
	margin: 0 20px 0 0;
`;

export const DeleteSVG = styled(deleteIcon)`
	margin: 0 0 0 0px;
`;

export const EditSVG = styled(editIcon)`
	margin: 0 0 0 0px;
`;

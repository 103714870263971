import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ReactModal from "react-modal";
import * as dayjs from "dayjs";
import { largeConfig } from "./utils";
import {
	Actions,
	CreatedBy,
	CreatedOn,
	DeleteSVG,
	EditSVG,
	IconSquare,
	LastUpdated,
	ModalContainer,
	Name,
	TableDiv,
} from "./styles";

import { ModalDelete } from "components";

export default function DictTableItens(props) {
	const { name, dictId, email, created, updated, role } = props;
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const handleDelete = () => {
		setDeleteOpen(true);
	};

	const handleCancel = () => {
		setDeleteOpen(false);
	};

	return (
		<>
			<ReactModal ariaHideApp={false} isOpen={isDeleteOpen} style={largeConfig}>
				<ModalContainer>
					<ModalDelete type="dictionary" id={dictId} closeModal={handleCancel} name={name} />
				</ModalContainer>
			</ReactModal>
			<TableDiv>
				<Name to={`edit/${name.replace(" ", "-").toLowerCase()}`} state={dictId}>
					{name}
				</Name>
				<CreatedBy>{email}</CreatedBy>
				<CreatedOn>{dayjs(created).format("DD-MM-YYYY")}</CreatedOn>
				<LastUpdated>{dayjs(updated).format("DD-MM-YYYY")}</LastUpdated>

				{role === "manager" && (
					<Actions>
						<NavLink to={`edit/${name.replace(" ", "-").toLowerCase()}`} state={dictId}>
							<IconSquare edit>
								<EditSVG />
							</IconSquare>
						</NavLink>
						<IconSquare>
							<DeleteSVG onClick={handleDelete} />
						</IconSquare>
					</Actions>
				)}
			</TableDiv>
		</>
	);
}

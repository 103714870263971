import React from "react";
import { Modal, Select } from "antd";
import { InfoDiv, StyledInput, StyledSelect, Value } from "./styles";

export default function ModalWorkspace(props) {
	const { Option } = Select;
	const { title, visible, submit, cancel, members, workspaces } = props;
	const { workspaceName, handleWorkspaceName, handleWorkspaceChange, workspaceId } = props;
	const filteredWorkspace = workspaces?.filter((item) => item._id === workspaceId)[0];

	const preSelectedOptions = filteredWorkspace?.users?.map((item) => {
		const newObject = {
			children: item.name,
			key: item._id,
			value: item._id,
		};
		return newObject;
	});

	return (
		<Modal title={title} visible={visible} onOk={submit} onCancel={cancel} maskClosable={false} destroyOnClose={true}>
			<InfoDiv>
				<Value>Workspace Name:</Value>
				<StyledInput value={workspaceName} onChange={handleWorkspaceName} placeholder="Workspace Name" />
				<Value>Workspace Members:</Value>
				<StyledSelect
					mode="tags"
					placeholder="Workspace Members"
					onChange={handleWorkspaceChange}
					defaultValue={preSelectedOptions}
				>
					{members.map((member, index) => (
						<Option key={member._id}>{member.name}</Option>
					))}
				</StyledSelect>
			</InfoDiv>
		</Modal>
	);
}

import axios from "axios";
import { store } from "store";

const api = axios.create({
	baseURL: "https://nameni-server.herokuapp.com/api",
});

api.interceptors.request.use(async (req) => {
	const { userData } = store.getState();
	try {
		if (userData.data) {
			const data = userData.data;
			req.headers.Authorization = `Bearer ${data.token}`;
		}
		return req;
	} catch (error) {
		console.log(error);
	}
});

export const createUser = (payload) => api.post("/user/new", payload);
export const loginUser = (payload) => api.post("/user/login", payload);
export const inviteUser = (payload) => api.post("/user/invite", payload);
export const getOneUser = (id) => api.get(`/user/${id}`);
export const updateUser = (id, payload) => api.put(`/user/${id}`, payload);
export const deleteUser = (id) => api.delete(`/user/${id}`);
export const resetPasswordEmail = (payload) => api.post(`/user/reset`, payload);

export const createDictionary = (payload) => api.post("/dictionaries/new", payload);
export const getUserDictionaries = (workspaceId) => api.get(`/dictionaries/${workspaceId}`);
export const deleteUserDictionary = (id, payload) => api.delete(`/dictionaries/${id}`, payload);
export const updateUserDictionary = (id, payload) => api.put(`/dictionaries/${id}`, payload);

export const createNomenclature = (payload) => api.post("/nomenclatures/new", payload);
export const getUserNomenclatures = (workspaceId) => api.get(`/nomenclatures/${workspaceId}`);
export const deleteUserNomenclature = (id, payload) => api.delete(`/nomenclatures/${id}`, { data: { payload } });
export const getOneNomenclature = (name) => api.get(`/nomenclature/${name}`);
export const updateNomenclature = (id, payload) => api.put(`/nomenclature/${id}`, payload);

export const getTeamUsers = (id) => api.get(`/team/${id}`);
export const updateTeamUser = (id, payload) => api.put(`/team/user/${id}`, payload);

export const getTeamWorkspaces = () => api.get(`/workspaces`);
export const getUserWorkspaces = () => api.get(`/workspaces/user`);
export const createWorkspace = (payload) => api.post(`/workspaces/new`, payload);
export const deleteWorkspace = (id) => api.delete(`/workspace/${id}`);
export const updateWorkspace = (id, payload) => api.put(`/workspace/${id}`, payload);

const apis = {
	createUser,
	loginUser,
	inviteUser,
	createDictionary,
	getUserDictionaries,
	deleteUserDictionary,
	updateUserDictionary,
	createNomenclature,
	getUserNomenclatures,
	deleteUserNomenclature,
	getOneNomenclature,
	updateNomenclature,
	getOneUser,
	updateUser,
	getTeamUsers,
	deleteUser,
	updateTeamUser,
	resetPasswordEmail,
	getTeamWorkspaces,
	createWorkspace,
	deleteWorkspace,
	updateWorkspace,
	getUserWorkspaces,
};

export default apis;

import api from "../../api";

export const getTeamWorkspaces = () => async (dispatch) => {
	try {
		dispatch({
			type: "GET_TEAM_WORKSPACE_PENDING",
		});
		const { data } = await api.getTeamWorkspaces();
		const workspaces = data.data[0].workspaces;
		dispatch({
			type: "GET_TEAM_WORKSPACE_SUCCESS",
			payload: workspaces,
		});
	} catch (error) {
		dispatch({
			type: "GET_TEAM_WORKSPACE_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const getUserWorkspaces = () => async (dispatch) => {
	try {
		dispatch({
			type: "GET_USER_WORKSPACE_PENDING",
		});
		const { data } = await api.getUserWorkspaces();
		const userWorkspaces = data.data;
		dispatch({
			type: "GET_USER_WORKSPACE_SUCCESS",
			payload: userWorkspaces,
		});
	} catch (error) {
		dispatch({
			type: "GET_USER_WORKSPACE_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const createWorkspace = (payload) => async (dispatch) => {
	try {
		dispatch({
			type: "CREATE_WORKSPACE_PENDING",
		});
		const { data } = await api.createWorkspace(payload);
		dispatch({
			type: "CREATE_WORKSPACE_SUCCESS",
			payload: data,
		});
		dispatch(getTeamWorkspaces());
	} catch (error) {
		dispatch({
			type: "GET_TEAM_WORKSPACE_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const deleteWorkspace = (id) => async (dispatch) => {
	try {
		dispatch({
			type: "DELETE_WORKSPACE_PENDING",
		});
		const { data } = await api.deleteWorkspace(id);
		dispatch({
			type: "DELETE_WORKSPACE_SUCCESS",
			payload: data,
		});
		dispatch(getTeamWorkspaces());
	} catch (error) {
		dispatch({
			type: "DELETE_WORKSPACE_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const updateWorkspace = (id, payload) => async (dispatch) => {
	try {
		dispatch({
			type: "UPDATE_WORKSPACE_PENDING",
		});
		const { data } = await api.updateWorkspace(id, payload);
		dispatch({
			type: "UPDATE_WORKSPACE_SUCCESS",
			payload: data,
		});
		dispatch(getTeamWorkspaces());
	} catch (error) {
		dispatch({
			type: "UPDATE_WORKSPACE_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const updateCurrentWorkspace = (payload) => async (dispatch) => {
	dispatch({
		type: "UPDATE_CURRENT_WORKSPACE",
		payload: payload,
	});
};

import { Tag, Space } from "antd";
import { Delete, Edit } from "./styles";

export const columnsFunction = (confirmDeleteWorkspace, handleEditWorkspace) => {
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Created By",
			dataIndex: "createdBy",
			key: "createdBy",
		},
		{
			title: "Users",
			key: "users",
			dataIndex: "users",
			render: (users) => (
				<span>
					{users.map((user) => {
						const color = user.role === "manager" ? "green" : "geekblue";
						return (
							<Tag color={color} key={user._id}>
								{user.name}
							</Tag>
						);
					})}
				</span>
			),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Space size="middle">
					<Edit onClick={() => handleEditWorkspace(record.name, record.users, record.key)}>Edit</Edit>
					<Delete onClick={() => confirmDeleteWorkspace(record.key, record.name)}>Delete</Delete>
				</Space>
			),
		},
	];
	return columns;
};

export const emptyFields = (notification, placement) => {
	notification.info({
		message: `Empty Fields.`,
		description: "Don't leave empty fields. Name and Members must not be blank.",
		placement,
		duration: 3,
	});
};

export const errorAPI = (notification, placement) => {
	notification.info({
		message: `Unexpected Error.`,
		description: "There was an unexpected error. Please refresh the page.",
		placement,
		duration: 3,
	});
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DetailsDiv, Header, InfoDiv, MainContainer, StyledButton, Title, Value, DeleteModal } from "./styles";
import { Table, notification } from "antd";
import { deleteUser } from "store/auth/authActions";
import { getTeamUsers, updateTeamUser, userInvite } from "store/team/teamActions";
import { columnsFunction, inviteErrorNotification, inviteSentNotification } from "./utils";
import ModalEditTeam from "components/ModalEditTeam";

export default function Team() {
	const dispatch = useDispatch();
	const [addUserModal, setAddUserModal] = useState(false);
	const [editUserModal, setEditUserModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [userRole, setUserRole] = useState("user");
	const [userEmail, setUserEmail] = useState("");
	const [userId, setUserId] = useState("");
	const [userWorkspace, setUserWorkspace] = useState([]);
	const { team, workspaces, isLoading } = useSelector((state) => state.team) || {};
	const { inviteError, inviteSent } = useSelector((state) => state.inviteUser || {});
	const user = useSelector((state) => state.userData.data);
	const role = useSelector((state) => state.userData.data.data.role);
	const inviterEmail = user?.data?.email;
	const inviterName = user?.data?.name;
	const teamId = user?.data?.team[0];

	const teamData = team.map((element, index) => {
		const userWorkspaces = workspaces.filter((item) => item.users.includes(element._id));

		const object = {
			key: element._id,
			name: element.name,
			email: element.email,
			workspaces: userWorkspaces,
			role: [element.role],
		};
		return object;
	});

	useEffect(() => {
		dispatch(getTeamUsers(teamId));
	}, [dispatch, teamId]);

	useEffect(() => {
		if (inviteSent) {
			inviteSentNotification(notification, "topRight");
		} else if (inviteError) {
			inviteErrorNotification(notification, "topRight");
		}
	}, [inviteSent, inviteError]);

	const handleUserRole = (e) => {
		setUserRole(e.target.value);
	};

	const showModal = () => {
		setAddUserModal(true);
	};

	const handleUserEmail = (e) => {
		setUserEmail(e.target.value);
	};

	const handleUserWorkspace = (value, name) => {
		setUserWorkspace(value);
	};

	const handleEditUser = (id, email) => {
		if (role === "manager") {
			setEditUserModal(true);
			setUserId(id);
			setUserEmail(email);
		}
	};

	const handleDeleteUser = (id) => {
		if (role === "manager") {
			setDeleteModal(true);
			setUserId(id);
		}
	};

	const handleDelete = () => {
		dispatch(deleteUser(userId, teamId));
		setUserId("");
		setDeleteModal(false);
	};

	const handleUserSubmit = () => {
		setConfirmLoading(true);
		const payload = {
			email: userEmail,
			role: userRole,
			inviterName: inviterName,
			inviterEmail: inviterEmail,
			workspaces: userWorkspace,
		};
		dispatch(userInvite(payload, teamId));

		setTimeout(() => {
			setAddUserModal(false);
			setConfirmLoading(false);
		}, 1000);
	};

	const handleUserEdit = () => {
		setConfirmLoading(true);
		const payload = {
			userId: userId,
			userRole: userRole,
			userEmail: userEmail,
		};
		dispatch(updateTeamUser(userId, payload));

		setTimeout(() => {
			setEditUserModal(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleCancel = () => {
		setAddUserModal(false);
		setDeleteModal(false);
		setEditUserModal(false);
		setUserId("");
		setUserEmail("");
	};

	return (
		<>
			<ModalEditTeam
				title="Add User"
				visible={addUserModal}
				submit={handleUserSubmit}
				loading={confirmLoading}
				cancel={handleCancel}
				userEmail={userEmail}
				handleUserEmail={handleUserEmail}
				disabled={false}
				handleUserRole={handleUserRole}
				handleUserWorkspace={handleUserWorkspace}
				userRole={userRole}
				workspaces={workspaces}
			/>
			<ModalEditTeam
				title="Edit User"
				visible={editUserModal}
				submit={handleUserEdit}
				loading={confirmLoading}
				cancel={handleCancel}
				userEmail={userEmail}
				disabled={true}
				handleUserRole={handleUserRole}
				userRole={userRole}
				handleUserWorkspace={handleUserWorkspace}
				workspaces={workspaces}
				userId={userId}
				edit
			/>
			<DeleteModal title="Delete User" visible={deleteModal} onOk={handleDelete} onCancel={handleCancel}>
				<InfoDiv>Are you sure you wanna delete this user?</InfoDiv>
			</DeleteModal>
			<MainContainer>
				<Header>
					<Title>TEAM</Title>
					{role === "manager" && (
						<StyledButton type="primary" onClick={showModal}>
							+Add User
						</StyledButton>
					)}
				</Header>
				<DetailsDiv>
					<Value>Manage your Nameni team members, including their permissions, on this page.</Value>
					<Table
						loading={isLoading}
						columns={columnsFunction(handleDeleteUser, handleEditUser)}
						dataSource={teamData}
					/>
				</DetailsDiv>
			</MainContainer>
		</>
	);
}

import api from "../../api";

export const nomeCreate = (payload, currentWorkspace) => async (dispatch) => {
	try {
		dispatch({
			type: "CREATE_NOMENCLATURE_PENDING",
		});
		const { data } = await api.createNomenclature(payload);
		dispatch({
			type: "CREATE_NOMENCLATURE_SUCCESS",
			payload: data,
		});
		dispatch(getUserNome(currentWorkspace.id));
	} catch (error) {
		dispatch({
			type: "CREATE_NOMENCLATURE_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const getUserNome = (workspaceId) => async (dispatch) => {
	try {
		dispatch({
			type: "GET_NOMENCLATURES_PENDING",
		});
		const { data } = await api.getUserNomenclatures(workspaceId);
		const nomenclatures = data.data;
		dispatch({
			type: "GET_NOMENCLATURES_SUCCESS",
			payload: nomenclatures,
		});
	} catch (error) {
		dispatch({
			type: "GET_NOMENCLATURES_ERROR",
		});
		console.log(error.response, error.request, error.message);
	}
};

export const deleteUserNome = (id, currentWorkspace, formulas) => async (dispatch) => {
	try {
		dispatch({
			type: "DELETE_NOMENCLATURE_PENDING",
		});
		const payload = { formulas: formulas, workspaceId: currentWorkspace.id, nomenclatureId: id };
		const { data } = await api.deleteUserNomenclature(id, payload);
		dispatch({
			type: "DELETE_NOMENCLATURE_SUCCESS",
			payload: data,
		});
		dispatch(getUserNome(currentWorkspace.id));
	} catch (error) {
		dispatch({
			type: "DELETE_NOMENCLATURE_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

export const updateUserNome = (id, payload, currentWorkspace) => async (dispatch) => {
	try {
		dispatch({
			type: "UPDATE_NOMENCLATURE_PENDING",
		});
		const { data } = await api.updateNomenclature(id, payload);
		dispatch(getUserNome(currentWorkspace.id));
		dispatch({
			type: "UPDATE_NOMENCLATURE_SUCCESS",
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: "UPDATE_NOMENCLATURE_ERROR",
		});
		console.log(error.message, error.response, error.request);
	}
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { resetPassword, userLogin } from "store/auth/authActions";
import { loginErrorMsg, missingEmail, sendEmail, wrongEmail } from "./utils";
import {
	LoginContainer,
	MainContainer,
	StyledInput,
	StyledButton,
	StyledForm,
	StyledButtons,
	MainTitle,
	VersionTitle,
} from "./styles";

export default function Home() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { pwError, pwSent } = useSelector((state) => state.resetPassword);
	const { loginLoading, loginError } = useSelector((state) => state.userLogin);

	useEffect(() => {
		if (pwError) {
			wrongEmail(notification, "topRight");
		} else if (pwSent) {
			sendEmail(notification, "topRight");
		} else if (loginError) {
			loginErrorMsg(notification, "topRight");
		}
	}, [pwError, pwSent, loginError]);

	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = () => {
		const user = { email, password };
		dispatch(userLogin(user, navigate));
		setEmail("");
		setPassword("");
	};

	const handleResetPassword = (e) => {
		if (email === "") {
			missingEmail(notification, "topRight");
		} else {
			dispatch(resetPassword({ email }));
		}
	};

	return (
		<MainContainer>
			<LoginContainer>
				<MainTitle>NAMENI</MainTitle>
				<VersionTitle>v 0.1</VersionTitle>
				<StyledForm onSubmit={handleSubmit}>
					<StyledInput type="email" placeholder="email" onChange={handleEmail} value={email} />
					<StyledInput type="password" placeholder="password" onChange={handlePassword} value={password} />
					<StyledButtons>
						<StyledButton type="primary" loading={loginLoading} onClick={handleSubmit} name="login">
							Login
						</StyledButton>
						{/* <StyledButton onClick={handleCreate} name="create">
							Create User
						</StyledButton> */}
						<StyledButton type="dashed" onClick={handleResetPassword}>
							Reset Password
						</StyledButton>
					</StyledButtons>
				</StyledForm>
			</LoginContainer>
		</MainContainer>
	);
}

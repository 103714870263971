import { React, useState } from "react";
import { useSelector } from "react-redux";
import { Radio, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
	Buttons,
	SelectCategory,
	StyledButton,
	SelectContainer,
	SelectTitle,
	StyledSelect,
	StyledInput,
	CategoryContainer,
	StyledRadioButton,
	Text,
	NoteWrapper,
	NoteCategory,
} from "./styles";
import { noteDetails } from "./utils";

export default function AddField(props) {
	const { Option } = Select;

	const [checked, setChecked] = useState("dictionary");
	const [inputValue, setInputValue] = useState("");
	const [dictLabel, setDictLabel] = useState("");
	const [dividerValue, setDivider] = useState("");
	const [noteValue, setNote] = useState("");
	const { dictionaries } = useSelector((state) => state.dictionaries);

	const RadioSelect = (e) => {
		setChecked(e.target.value);
	};

	const DictDropdown = (e) => {
		setDictLabel(e);
	};

	const dividerDropdown = (e) => {
		setDivider(e);
	};

	const handleInputValue = (e) => {
		setInputValue(e.target.value);
	};

	const handleNoteValue = (e) => {
		setNote(e.target.value);
	};

	const handleSave = (e) => {
		sendField();
		props.closeModal();
	};

	const sendField = () => {
		if (checked === "dictionary") {
			const data = { fieldType: "dictionary", fieldName: dictLabel, note: noteValue };
			props.getField(data);
		} else if (checked === "textfield") {
			const data = { fieldType: "textfield", fieldName: inputValue, note: noteValue };
			props.getField(data);
		} else if (checked === "divider") {
			const data = { fieldType: "divider", fieldName: dividerValue, details: [{ value: dividerValue }] };
			props.getField(data);
		} else if (checked === "uniqueId") {
			const data = { fieldType: "uniqueId", fieldName: "uniqueId", details: [{ value: "uniqueId" }] };
			props.getField(data);
		}
	};

	const dividers = ["/", "_", "|", "+", "-"];

	return (
		<SelectContainer>
			<SelectTitle>Add Field</SelectTitle>
			<SelectCategory>Category</SelectCategory>

			<Radio.Group onChange={RadioSelect} value={checked} buttonStyle="solid" size="large">
				<CategoryContainer>
					<StyledRadioButton value="dictionary">Dictionary</StyledRadioButton>
					<StyledRadioButton value="textfield">Text Field</StyledRadioButton>
					<StyledRadioButton value="divider">Divider</StyledRadioButton>
					<StyledRadioButton value="uniqueId">Unique ID</StyledRadioButton>
				</CategoryContainer>
			</Radio.Group>
			{checked === "dictionary" && (
				<>
					<SelectCategory>Select Dictionary</SelectCategory>
					<StyledSelect onChange={DictDropdown} defaultValue="">
						{dictionaries.map((el, index) => (
							<Option value={el.name} key={el._id} id={el._id}>
								{el.name}
							</Option>
						))}
					</StyledSelect>
				</>
			)}
			{checked === "divider" && (
				<>
					<SelectCategory>Select Divider</SelectCategory>
					<StyledSelect onChange={dividerDropdown} defaultValue="">
						{dividers.map((el, i) => (
							<Option value={el} key={i} id={i}>
								{el}
							</Option>
						))}
					</StyledSelect>
				</>
			)}
			{checked === "textfield" && (
				<>
					<SelectCategory>Name your field:</SelectCategory>
					<StyledInput placeholder="Name" onChange={handleInputValue} value={inputValue} />
				</>
			)}
			{checked !== "divider" && checked !== "uniqueId" && (
				<>
					<NoteWrapper>
						<NoteCategory>Note:</NoteCategory>
						<Tooltip placement="top" title={noteDetails}>
							<InfoCircleOutlined />
						</Tooltip>
					</NoteWrapper>
					<Text rows={2} onChange={handleNoteValue} value={noteValue} />
				</>
			)}
			<Buttons>
				<StyledButton onClick={props.closeModal}>Cancel</StyledButton>
				<StyledButton onClick={handleSave} type="primary">
					Save
				</StyledButton>
			</Buttons>
		</SelectContainer>
	);
}

export const transformF = (transformCase, value, utm) => {
	if (utm === "url") {
		return value;
	} else if (transformCase === "upper") {
		return value.toUpperCase();
	} else if (transformCase === "lower") {
		return value.toLowerCase();
	} else {
		return value;
	}
};

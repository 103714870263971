import styled from "styled-components";
import { Input, Radio, Select } from "antd";

export const InfoDiv = styled.div`
	display: flex;
	flex-flow: column;
`;

export const Value = styled.span`
	font-size: 14px;
	margin-bottom: 20px;
`;

export const StyledInput = styled(Input)`
	height: 55px;
	border-radius: 7px !important;
	margin-bottom: 20px !important;
`;

export const CategoryContainer = styled.div`
	display: flex;
	flex-flow: row;
	width: 100%;
	gap: 20px;
	margin-bottom: 20px;
`;

export const StyledRadioButton = styled(Radio.Button)`
	width: 100%;
	text-align: center;
	height: 40px !important;
`;

export const StyledSelect = styled(Select)`
	& .ant-select-selector {
		min-height: 55px;
		align-items: center !important;
		border-radius: 7px !important;
	}
`;

import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as Dictionaries } from "./Dictionaries.svg";
import { ReactComponent as Links } from "./Links.svg";
import { ReactComponent as Logout } from "./Logout.svg";
import { ReactComponent as Nomenclatures } from "./Nomenclatures.svg";
import { ReactComponent as Team } from "./Teams.svg";
import { ReactComponent as Account } from "./settings.svg";
import { ReactComponent as Workspace } from "./workspace.svg";
import logo from "./nameni-logo.png";

export const NavContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: #192a3e;
	width: 383px;
`;

export const NameniLogo = styled.img.attrs({
	src: `${logo}`,
})`
	width: 200px;
	align-self: flex-start;
`;

export const StyledLink = styled(NavLink)`
	display: flex;
	flex-direction: row;
	color: white;
	background: transparent;
	width: 100%;
	min-height: 65px;
	align-items: center;

	&.active {
		background: #303f51;
	}
`;

export const ItensDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	/* justify-content: center; */
`;

export const LogoDiv = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

export const Logo = styled.img`
	width: 250px;
`;

export const NavItemDiv = styled.div`
	display: flex;
	flex-direction: row;
	color: white;
	background: #303f51;
	width: 100%;
	min-height: 65px;
	align-items: center;
`;

export const NavItem = styled.span`
	margin: 0 0 0 12px;
	font-size: 20px;
`;

export const LogoutDiv = styled.div`
	display: flex;
	flex-direction: row;
	color: white;
	min-height: 65px;
	align-items: center;
	cursor: pointer;

	:hover {
		color: #1890ff;
	}
`;

export const NavLogout = styled.span`
	margin: 0 0 0 12px;
	font-size: 20px;
`;

export const DictionariesSVG = styled(Dictionaries)`
	margin: 0 0 0 40px;
`;

export const LinksSVG = styled(Links)`
	margin: 0 0 0 40px;
`;

export const LogoutSVG = styled(Logout)`
	margin: 0 0 0 40px;
`;

export const NomenclaturesSVG = styled(Nomenclatures)`
	margin: 0 0 0 40px;
`;

export const TeamSVG = styled(Team)`
	margin: 0 0 0 40px;
`;

export const AccountSVG = styled(Account)`
	margin: 0 0 0 40px;
`;

export const WorkSVG = styled(Workspace)`
	margin: 0 0 0 40px;
`;

const initialState = {
	nomenclatures: [],
	isLoading: false,
	hasError: false,
	hasData: false,
};

export default function nomenclaturesReducer(state = initialState, action) {
	// eslint-disable-next-line default-case
	switch (action.type) {
		case "GET_NOMENCLATURES_PENDING":
			return {
				...state,
				isLoading: true,
				hasError: false,
				hasData: false,
			};
		case "GET_NOMENCLATURES_SUCCESS":
			return {
				...state,
				isLoading: false,
				hasError: false,
				hasData: true,
				nomenclatures: action.payload,
			};
		case "GET_NOMENCLATURES_ERROR":
			return {
				...state,
				isLoading: false,
				hasData: false,
				hasError: true,
			};
	}
	return state;
}

import styled from "styled-components";
import { Button, Input } from "antd";

export const MainContainer = styled.div`
	display: flex;
	background: #192a3e;
	height: 100vh;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

export const MainTitle = styled.h1`
	font-size: 60px;
	font-weight: 900;
	margin: 0;
	padding: 0%;
	line-height: 1;
`;

export const VersionTitle = styled.span`
	font-size: 18px;
	font-weight: 300;
	font-style: italic;
	margin: 0;
	margin: 0 0 20px 0;
`;

export const LoginContainer = styled.div`
	display: flex;
	background: #f5f6fa;
	height: 500px;
	width: 500px;
	border-radius: 12px;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
`;

export const StyledForm = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
	gap: 10px;
`;

export const StyledInput = styled(Input)`
	width: 70%;
`;

export const StyledButtons = styled.div`
	display: flex;
	flex-flow: column;
	gap: 10px;
	width: 70%;
`;

export const StyledButton = styled(Button)`
	width: 100%;
`;

import styled from "styled-components";
import { Button } from "antd";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	height: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px !important;
	width: 250px;
	height: 55px !important;
`;

export const DictTable = styled.div`
	display: flex;
	flex-flow: column;
`;

export const EmptyDict = styled.span`
	margin: 20px;
`;

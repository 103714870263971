import styled from "styled-components";
import { Button, Input } from "antd";
// import { Collapse } from "antd";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as editIcon } from "./edit.svg";

export const MainContainer = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
`;

export const Header = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin: 20px;
`;

export const Title = styled.span`
	font-size: 24px;
	font-weight: 700;
	flex-grow: 1;
`;

export const StyledButton = styled(Button)`
	border-radius: 7px;
	width: 250px;
	height: 55px;
`;

export const NameDiv = styled.div`
	display: flex;
	flex-flow: row;
	margin: 0 20px 20px 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #f0f0f0;
`;

export const DetailsDiv = styled.div`
	display: flex;
	flex-flow: column;
	flex-grow: 1;
`;

export const NameValue = styled.span`
	font-size: 14px;
	margin-bottom: 10px;
`;

export const NameInput = styled(Input)`
	width: 400px;
	height: 55px;
	border-radius: 7px;
	margin-bottom: 20px;
`;

export const TransformValue = styled.span`
	font-size: 14px;
`;

export const NoteWrapper = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	margin-bottom: 10px;
	gap: 5px;
`;

export const ButtonsDiv = styled.div`
	display: flex;
	flex-flow: row;
	align-items: flex-start;
`;

export const CollapseDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 20px 20px 20px;
	border-radius: 7px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #d9d9d9;
`;

export const FormulaDetailsDiv = styled.div`
	display: flex;
	flex-flow: row;
	align-items: center;
	height: 50px;
`;

export const FormulaTitle = styled.span`
	display: flex;
	align-items: center;
	height: 50px;
	font-weight: 500;
	margin-left: 20px;
	flex-grow: 1;
`;

export const IconSquare = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 25px;
	height: 25px;
	background-color: ${(props) => (props.edit ? "#E7DEFE" : "#FFE1E2")};
	cursor: pointer;
`;

export const Actions = styled.div`
	display: flex;
	flex-flow: row;
	gap: 10px;
	margin: 0 20px 0 0;
`;

export const DeleteSVG = styled(deleteIcon)`
	margin: 0 0 0 0px;
`;

export const EditSVG = styled(editIcon)`
	margin: 0 0 0 0px;
`;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu, Dropdown, Select } from "antd";
import { ArrowSVG, NavBarDiv, UserButton, Username, UserSVG, WorkspaceSelect } from "./styles";
import { getUserWorkspaces, updateCurrentWorkspace } from "store/workspaces/workspaceActions";

export default function NavBar() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.userData.data);
	const userName = user?.data?.email;
	const { userWorkspaces, currentWorkspace, hasData } = useSelector((state) => state.workspaces);
	const { Option } = Select;

	useEffect(() => {
		dispatch(getUserWorkspaces());
	}, [dispatch]);

	useEffect(() => {
		if (currentWorkspace === false || currentWorkspace.name === undefined) {
			const payload = { name: userWorkspaces[0]?.name, id: userWorkspaces[0]?._id };
			dispatch(updateCurrentWorkspace(payload));
		}
	}, [dispatch, currentWorkspace, userWorkspaces.length]);

	const menu = (
		<Menu>
			<Menu.Item key="1">
				<NavLink to="nomenclatures">Nomenclatures</NavLink>
			</Menu.Item>
			<Menu.Item key="2">
				<NavLink to="dictionaries">Dictionaries</NavLink>
			</Menu.Item>
			<Menu.Item key="3">
				<NavLink to="team">Team</NavLink>
			</Menu.Item>
		</Menu>
	);

	const handleWorkspaceChange = (value, id) => {
		const payload = { name: id.value, id: id.id };
		dispatch(updateCurrentWorkspace(payload));
	};

	return (
		<NavBarDiv>
			{currentWorkspace?.name && (
				<>
					<WorkspaceSelect value={currentWorkspace.name} onChange={handleWorkspaceChange} style={{ height: 55 }}>
						{userWorkspaces.map((workspace) => (
							<Option value={workspace.name} id={workspace._id} key={workspace._id}>
								Workspace: {workspace.name}
							</Option>
						))}
					</WorkspaceSelect>
					<Dropdown overlay={menu} trigger="click">
						<UserButton>
							<UserSVG />
							<Username>{userName}</Username>
							<ArrowSVG />
						</UserButton>
					</Dropdown>
				</>
			)}
		</NavBarDiv>
	);
}

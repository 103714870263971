import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOneUser, userUpdate } from "store/auth/authActions";
import {
	LoginContainer,
	MainContainer,
	StyledInput,
	StyledButton,
	StyledForm,
	StyledButtons,
	MainTitle,
	VersionTitle,
} from "./styles";

export default function UserActivate() {
	const { data } = useSelector((state) => state.userActivate.userActivate || {});
	const userEmail = data?.email;
	const [userName, setName] = useState(data?.name || "");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userId = location.pathname.replace("/activate/", "");

	useEffect(() => {
		dispatch(getOneUser(userId));
	}, [userId, dispatch]);

	const handleName = (e) => {
		setName(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleConfirm = (e) => {
		setConfirmPassword(e.target.value);
	};

	const handleSubmit = (e) => {
		if (password === confirmPassword) {
			const payload = {
				email: userEmail,
				name: userName,
				password: password,
			};
			dispatch(userUpdate(navigate, payload, userId));
		}
	};

	return (
		<MainContainer>
			<LoginContainer>
				<MainTitle>NAMENI</MainTitle>
				<VersionTitle>v 0.1</VersionTitle>
				<StyledForm onSubmit={handleSubmit}>
					<StyledInput type="name" placeholder="name" onChange={handleName} value={userName} />
					<StyledInput type="email" placeholder="email" value={userEmail} disabled />
					<StyledInput type="password" placeholder="password" onChange={handlePassword} value={password} />
					<StyledInput
						type="password"
						placeholder="confirm password"
						onChange={handleConfirm}
						value={confirmPassword}
					/>
					<StyledButtons>
						<StyledButton type="primary" onClick={handleSubmit} name="create">
							Create User
						</StyledButton>
					</StyledButtons>
				</StyledForm>
			</LoginContainer>
		</MainContainer>
	);
}
